<template>
  <div v-resize="onResize" class="main" id="service">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12">
        <!-- desktop -->
        <div v-show="mobile == false">
          <div class="text-center">
            <h1 :style="mobile ? 'font-family: Museo Sans Bold; font-size: 30px;' : 'font-family: Museo Sans Bold; font-size: 40px;'">Our Service</h1>
          </div>

          <div class="text-center ma-5">     
            <v-row justify="center">
              <v-slide-group
                show-arrows
                class="mb-4"
              >
                <v-slide-item>
                  <div style="padding: 9px;">
                    <v-hover v-slot="{ hover }">
                      <v-card v-model="body_repair" @click="body_repair" width="200" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_body_repair ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #BDBDBD;' : 'border: 4px solid #E0E0E0; background-color: #E0E0E0;'" :elevation="show_body_repair ? 6 : 0">
                        <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan1.png" height="auto"></v-img>
                      </v-card>
                    </v-hover>  
                  </div>
                </v-slide-item>

                <v-slide-item>
                  <div style="padding: 9px;">
                    <v-hover v-slot="{ hover }">
                      <!-- <v-card v-model="body_fabrikasi" @click="body_fabrikasi" width="200" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-2" :color="show_body_fabrikasi ? '#eb2028' : hover ? 'grey lighten-1' : 'grey lighten-2'" :elevation="show_body_fabrikasi ? 6 : 0"></v-card> -->
                      <v-card v-model="body_fabrikasi" @click="body_fabrikasi" width="200" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_body_fabrikasi ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #BDBDBD;' : 'border: 4px solid #E0E0E0; background-color: #E0E0E0;'" :elevation="show_body_fabrikasi ? 6 : 0">
                        <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan2.png" height="auto"></v-img>
                      </v-card>
                    </v-hover>
                  </div>
                </v-slide-item>

                <v-slide-item>
                  <div style="padding: 9px;">
                    <v-hover v-slot="{ hover }">
                      <v-card v-model="evekuasi" @click="evekuasi" width="200" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_evakuasi ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #BDBDBD;' : 'border: 4px solid #E0E0E0; background-color: #E0E0E0;'" :elevation="show_evakuasi ? 6 : 0">
                        <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan4.png" height="auto"></v-img>
                      </v-card>
                    </v-hover>  
                  </div>  
                </v-slide-item>

                <v-slide-item>
                  <div style="padding: 9px;">
                    <v-hover v-slot="{ hover }">
                      <v-card v-model="mobilization" @click="mobilization" width="200" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_mobilization ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #BDBDBD;' : 'border: 4px solid #E0E0E0; background-color: #E0E0E0;'" :elevation="show_mobilization ? 6 : 0">
                        <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan3.png" height="auto"></v-img>
                      </v-card>
                    </v-hover>  
                  </div>
                </v-slide-item>

                <v-slide-item>
                  <div style="padding: 9px;">
                    <!-- <v-img style="position: absolute; z-index: 1; top: 18px; right: 140px;" src="../assets/ulin_logo_small.png" height="auto" width="70px"></v-img> -->
                    <v-hover v-slot="{ hover }">
                      <v-card v-model="special_repair" @click="special_repair" width="200" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_special_repair ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #BDBDBD;' : 'border: 4px solid #E0E0E0; background-color: #E0E0E0;'" :elevation="show_special_repair ? 6 : 0">
                        <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan5.png" height="auto"></v-img>
                      </v-card>
                    </v-hover>
                  </div>
                </v-slide-item>
              </v-slide-group>
            </v-row>

            <v-sheet style="margin: auto;" class="text-center mx-auto" max-width="1070px">
              <v-expand-transition>
                <div v-show="show_body_repair">
                  <v-card class="rounded-xl" elevation="6" style="border: 4px solid #F5F5F5; background-color: #F5F5F5;">
                    <v-card-title class="mb-n2">
                      <span style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Body Repair</span>
                      <v-spacer></v-spacer>
                      <v-icon @click="close_body_repair" size="28px" color="#eb2028">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-sheet class="text-left" style="background-color: #F5F5F5;">
                        <v-row no-gutters>
                          <div style="width: 4%;"><v-icon size="14" color="#eb2028">mdi-circle</v-icon></div>
                          <div style="width: 96%; font-family: Museo Sans; font-size: 16px;"><span style="font-family: Museo Sans Bold;"><a target="_blank" href="https://www.instagram.com/trinityauto.car/" style="color: black;">Body Repair (CAR).</a></span> Layanan untuk perbaikan klaim asuransi dan perbaikan mandiri (non-asuransi) kendaraan bermotor</div>
                        </v-row>
                        <v-row no-gutters>
                          <div style="width: 4%;"><v-icon size="14" color="#eb2028">mdi-circle</v-icon></div>
                          <div style="width: 96%; font-family: Museo Sans; font-size: 16px;"><span style="font-family: Museo Sans Bold;"><a target="_blank" href="https://www.instagram.com/trinityauto.he/" style="color: black;">Body & General Repair (HE).</a></span> Layanan untuk perbaikan klaim asuransi dan perbaikan mandiri (non-asuransi) kendaraan dan alat berat, baik pekerjaan Body Repair maupun rekondisi komponen (OVH)</div>
                        </v-row>
                        <!-- <div style="font-family: Museo Sans; font-size: 16px;"><span style="font-family: Museo Sans Bold;">Body Repair (CAR).</span> Layanan klaim dan non-klaim perbaikan kendaraan bermotor.</div>
                        <div style="font-family: Museo Sans; font-size: 16px;"><span style="font-family: Museo Sans Bold;">Body & General Repair (HE). </span>Layanan klaim dan non-klaim perbaikan untuk kendaraan dan alat berat, baik pekerjaan Body Repair maupun rekondisi komponen (OVH).</div> -->
                      </v-sheet> 
                    </v-card-text>
                  </v-card>

                  <!-- <v-alert
                    elevation="6"
                    color="white"
                    class="text-left rounded-xl"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Body Repair</div>
                        <v-row no-gutters>
                          <div style="font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;">Body Repair (CAR).</span> Layanan klaim dan non-klaim perbaikan kendaraan bermotor.</div>
                        </v-row>
                        <v-row no-gutters>
                          <div style="font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;">Body & General Repair (HE).</span>Body & General Repair (HE). Layanan klaim dan non-klaim perbaikan untuk kendaraan dan alat berat, baik pekerjaan Body Repair maupun rekondisi komponen (OVH).</div>
                        </v-row>
                      </v-col>
                      
                      <v-col class="shrink">
                        <v-icon color="black" @click="close_body_repair">mdi-close-circle</v-icon>
                      </v-col>
                    </v-row>
                  </v-alert> -->
                </div>
              </v-expand-transition>

              <v-expand-transition>
                <div v-show="show_body_fabrikasi">
                  <v-card class="rounded-xl" elevation="6" style="border: 4px solid #F5F5F5; background-color: #F5F5F5;">
                    <v-card-title class="mb-n2">
                      <span style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Fabrikasi</span>
                      <v-spacer></v-spacer>
                      <v-icon @click="close_body_fabrikasi" size="28px" color="#eb2028">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-sheet class="text-left" style="background-color: #F5F5F5;">
                        <!-- <div style="font-family: Museo Sans; font-size: 16px;">Layanan pekerjaan pembuatan tangki stok maupun tangki kendaraan</div> -->
                        <div style="font-family: Museo Sans; font-size: 16px;">Layanan pekerjaan pembuatan/perbaikan attachment atau perlengkapan kendaraan, truck, dan alat berat. Seperti lube truck attachment, crane truck attachment, tangki, man haul, dan lain-lain</div>
                      </v-sheet> 
                    </v-card-text>
                  </v-card>
                  <!-- <v-alert
                    elevation="6"
                    color="white"
                    class="text-left rounded-xl"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Fabrikasi</div>
                        <v-row no-gutters>
                          <div style="font-family: Museo Sans;">Layanan pekerjaan pembuatan tangki stok maupun tangki kendaraan.</div>
                        </v-row>
                      </v-col>
                      
                      <v-col class="shrink">
                        <v-icon color="black" @click="close_body_fabrikasi">mdi-close-circle</v-icon>
                      </v-col>
                    </v-row>
                  </v-alert> -->
                </div>
              </v-expand-transition>

              <v-expand-transition>
                <div v-show="show_evakuasi">
                  <v-card class="rounded-xl" elevation="6" style="border: 4px solid #F5F5F5; background-color: #F5F5F5;">
                    <v-card-title class="mb-n2">
                      <span style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Evakuasi</span>
                      <v-spacer></v-spacer>
                      <v-icon @click="close_evakuasi" size="28px" color="#eb2028">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-sheet class="text-left" style="background-color: #F5F5F5;">
                        <div style="font-family: Museo Sans; font-size: 16px;">Layanan pekerjaan penarikan/evakuasi untuk alat-alat berat yang tenggelam, tertimbun tanah longsor, jatuh ke jurang, dan lain sebagainya</div>
                      </v-sheet> 
                    </v-card-text>
                  </v-card>
                  <!-- <v-alert
                    elevation="6"
                    color="white"
                    class="text-left rounded-xl"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <div style="font-family: Museo Sans Bold; font-weight: bold; font-size: 20px; color: #eb2028;">Evakuasi</div>
                        <v-row no-gutters>
                          <div style="font-family: Museo Sans;">Layanan pekerjaan penarikan/ evakuasi untuk alat-alat berat yang tenggelam, tertimbun tanah longsor, jatuh ke jurang, dan lain sebagainya.</div>
                        </v-row>
                      </v-col>
                      
                      <v-col class="shrink">
                        <v-icon color="black" @click="close_evakuasi">mdi-close-circle</v-icon>
                      </v-col>
                    </v-row>
                  </v-alert> -->
                </div>
              </v-expand-transition>
              
              <v-expand-transition>
                <div v-show="show_mobilization">
                  <v-card class="rounded-xl" elevation="6" style="border: 4px solid #F5F5F5; background-color: #F5F5F5;">
                    <v-card-title class="mb-n2">
                      <span style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Mobilization</span>
                      <v-spacer></v-spacer>
                      <v-icon @click="close_mobilization" size="28px" color="#eb2028">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-sheet class="text-left" style="background-color: #F5F5F5;">
                        <div style="font-family: Museo Sans; font-size: 16px;"><span style="font-family: Museo Sans Bold;">Melalui <a target="_blank" href="https://www.instagram.com/trinitylogistik.id/" style="color: black;">Trinity Sarana Logistik.</a></span> Menyediakan layanan mobilisasi kendaraan, truck, dan alat berat</div>
                      </v-sheet> 
                    </v-card-text>
                  </v-card>
                  <!-- <v-alert
                    elevation="6"
                    color="white"
                    class="text-left rounded-xl"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Mobilization</div>
                        <v-row no-gutters>
                          <div style="font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;">Bagian dari Trinity Logistik.</span> Layanan mobilisasi/ derek kendaraan roda empat atau lebih.</div>
                        </v-row>
                      </v-col>
                      
                      <v-col class="shrink">
                        <v-icon color="black" @click="close_mobilization">mdi-close-circle</v-icon>
                      </v-col>
                    </v-row>
                  </v-alert> -->
                </div>
              </v-expand-transition>

              <v-expand-transition>
                <div v-show="show_special_repair">
                  <v-card class="rounded-xl" elevation="6" style="border: 4px solid #F5F5F5; background-color: #F5F5F5;">
                    <v-card-title class="mb-n2">
                      <span style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Special Repair & Modification</span>
                      <v-spacer></v-spacer>
                      <v-icon @click="close_special_repair" size="28px" color="#eb2028">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-sheet class="text-left" style="background-color: #F5F5F5;">
                        <div style="font-family: Museo Sans; font-size: 16px;"><span style="font-family: Museo Sans Bold;">Melalui <a target="_blank" href="https://www.instagram.com/ulin.autogarage/" style="color: black;">ULIN.</a></span> Menyediakan layanan modifikasi, body/underbody protection coating, detailing, dan penjualan berbagai aksesoris kendaraan 4WD</div>
                      </v-sheet> 
                    </v-card-text>
                  </v-card>
                  <!-- <v-alert
                    elevation="6"
                    color="white"
                    class="text-left rounded-xl"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Special Repair & Modification</div>
                        <v-row no-gutters>
                          <div style="font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;">Bagian dari ULIN.</span> Menyediakan layanan modifikasi, body/ underbody protection coating , detailing dan penjualan berbagai aksesoris kendaraan dengan harapan dapat memenuhi keinginan automotive enthusiast.</div>
                        </v-row>
                      </v-col>
                      
                      <v-col class="shrink">
                        <v-icon color="black" @click="close_special_repair">mdi-close-circle</v-icon>
                      </v-col>
                    </v-row>
                  </v-alert> -->
                </div>
              </v-expand-transition>
            </v-sheet>
          </div>

          <!-- <div class="text-ceter">
            <v-hover v-slot="{ hover }">
              <v-card
                @click="show = !show" width="200" height="auto" class="mx-auto rounded-xl" :style="hover ? 'cursor: pointer; ' : ''" :color="show ? '#eb2028' : hover ? '#eb2028' : 'grey lighten-2'" :elevation="hover ? 10 : 1" :class="{ 'on-hover': hover }"
              >
                <v-img src="../assets/layanan/layanan1.png" height="auto"></v-img>

                <v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>
                      <v-icon size="15">mdi-circle</v-icon>
                      <span>Body Repair (CAR) . Layanan klaim dan non-klaim perbaikan kendaraan bermotor.</span>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-hover>
          </div> -->

          <!-- <div class="text-center ma-5">     
            <v-row justify="center">
              <v-slide-group
                show-arrows
                class="mt-n3"
              >
                <v-slide-item
                  v-for="(service, i) in services" :key="i"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card width="200" height="auto" class="ma-4 mb-7 rounded-xl" :style="hover ? 'cursor: pointer; ' : ''" :color="hover ? '#eb2028' : 'grey lighten-2'" :elevation="hover ? 10 : 1" :class="{ 'on-hover': hover }">
                      <img style="width: 100%; aspect-ratio: 2 / 2.6; object-fit: contain; margin-bottom: -7px;" loading="lazy" :src="service.src"/>
                    </v-card>
                  </v-hover>
                </v-slide-item>
              </v-slide-group>
            </v-row>
          </div> -->
        </div>

        <!-- mobile -->
        <div v-show="mobile == true" class="text-center mt-5 mb-5">  
          <div class="text-center mb-3">
            <h1 :style="mobile ? 'font-family: Museo Sans Bold; font-size: 30px;' : 'font-family: Museo Sans Bold; font-size: 40px;'">Our Service</h1>
          </div>

          <v-window v-model="onboarding">
            <v-window-item>
              <v-hover v-slot="{ hover }">
                <v-card v-model="body_repair" @click="body_repair" width="250" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_body_repair ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #eb2028;' : 'border: 4px solid #eb2028; background-color: #eb2028;'" :elevation="show_body_repair ? 6 : 6">
                  <v-img v-if="body_repair" :aspect-ratio="4/5.2" src="../assets/layanan/layanan1.png" height="auto"></v-img>
                  <v-img v-else :aspect-ratio="4/5.2" src="../assets/layanan/layanan2.png" height="auto"></v-img>
                </v-card>
              </v-hover> 

              <v-sheet style="margin: auto;" class="text-center mx-auto pa-3" max-width="1070px">
                <v-expand-transition>
                  <div v-show="show_body_repair">
                    <v-alert
                      elevation="6"
                      style="border: 4px solid #F5F5F5; background-color: #F5F5F5;"
                      class="text-left rounded-xl"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Body Repair</div>
                          <v-row no-gutters>
                            <div style="width: 10%;"><v-icon size="14" color="#eb2028">mdi-circle</v-icon></div>
                            <div style="width: 90%; font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;"><a target="_blank" href="https://www.instagram.com/trinityauto.car/" style="color: black;">Body Repair (CAR).</a></span> Layanan untuk perbaikan klaim asuransi dan perbaikan mandiri (non-asuransi) kendaraan bermotor</div>
                          </v-row>
                          <v-row no-gutters>
                            <div style="width: 10%;"><v-icon size="14" color="#eb2028">mdi-circle</v-icon></div>
                            <div style="width: 90%; font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;"><a target="_blank" href="https://www.instagram.com/trinityauto.he/" style="color: black;">Body & General Repair (HE).</a></span> Layanan untuk perbaikan klaim asuransi dan perbaikan mandiri (non-asuransi) kendaraan dan alat berat, baik pekerjaan Body Repair maupun rekondisi komponen (OVH)</div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row no-gutters align="center" justify="center" class="mt-3">
                        <v-icon size="30px" color="#eb2028" @click="close_body_repair">mdi-close</v-icon>
                      </v-row>                   
                    </v-alert>
                  </div>
                </v-expand-transition>
              </v-sheet>
            </v-window-item>

            <v-window-item>
              <v-hover v-slot="{ hover }">
                <v-card v-model="body_fabrikasi" @click="body_fabrikasi" width="250" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_body_fabrikasi ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #eb2028;' : 'border: 4px solid #eb2028; background-color: #eb2028;'" :elevation="show_body_fabrikasi ? 6 : 0">
                  <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan2.png" height="auto"></v-img>
                </v-card>
              </v-hover>

              <v-sheet style="margin: auto;" class="text-center mx-auto pa-3" max-width="1070px">
                <v-expand-transition>
                  <div v-show="show_body_fabrikasi">
                    <v-alert
                      elevation="6"
                      style="border: 4px solid #F5F5F5; background-color: #F5F5F5;"
                      class="text-left rounded-xl"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Fabrikasi</div>
                          <v-row no-gutters>
                            <!-- <div style="width: 4%;"><v-icon size="14" color="#eb2028">mdi-circle</v-icon></div> -->
                            <!-- <div style="font-family: Museo Sans;">Layanan pekerjaan pembuatan tangki stok maupun tangki kendaraan</div> -->
                            <div style="font-family: Museo Sans;">Layanan pekerjaan pembuatan/perbaikan attachment atau perlengkapan kendaraan, truck, dan alat berat. Seperti lube truck attachment, crane truck attachment, tangki, man haul, dan lain-lain</div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row no-gutters align="center" justify="center" class="mt-3">
                        <v-icon size="30px" color="#eb2028" @click="close_body_fabrikasi">mdi-close</v-icon>
                      </v-row> 
                    </v-alert>
                  </div>
                </v-expand-transition>
              </v-sheet>
            </v-window-item>

            <v-window-item>
              <v-hover v-slot="{ hover }">
                <v-card v-model="evekuasi" @click="evekuasi" width="250" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_evakuasi ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #eb2028;' : 'border: 4px solid #eb2028; background-color: #eb2028;'" :elevation="show_evakuasi ? 6 : 0">
                <!-- <v-card v-model="evekuasi" @click="evekuasi" width="250" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-2" :style="show_evakuasi ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #BDBDBD;' : 'border: 4px solid #eb2028; background-color: #E0E0E0;'" :elevation="show_evakuasi ? 6 : 0"> -->
                  <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan4.png" height="auto"></v-img>
                </v-card>
              </v-hover> 

              <v-sheet style="margin: auto;" class="text-center mx-auto pa-3" max-width="1070px">
                <v-expand-transition>
                  <div v-show="show_evakuasi">
                    <v-alert
                      elevation="6"
                      style="border: 4px solid #F5F5F5; background-color: #F5F5F5;"
                      class="text-left rounded-xl"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Evakuasi</div>
                          <v-row no-gutters>
                            <!-- <div style="width: 4%;"><v-icon size="14" color="#eb2028">mdi-circle</v-icon></div> -->
                            <!-- <div style="font-family: Museo Sans;">Layanan pekerjaan penarikan/evakuasi untuk alat-alat berat yang tenggelam, tertimbun tanah longsor, jatuh ke jurang, dan lain sebagainya</div> -->
                            <div style="font-family: Museo Sans;">Layanan pekerjaan penarikan/evakuasi untuk alat-alat berat yang tenggelam, tertimbun tanah longsor, jatuh ke jurang, dan lain sebagainya</div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row no-gutters align="center" justify="center" class="mt-3">
                        <v-icon size="30px" color="#eb2028" @click="close_evakuasi">mdi-close</v-icon>
                      </v-row> 
                    </v-alert>
                  </div>
                </v-expand-transition>
              </v-sheet>
            </v-window-item>

            <v-window-item>
              <v-hover v-slot="{ hover }">
                <v-card v-model="mobilization" @click="mobilization" width="250" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_mobilization ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #eb2028;' : 'border: 4px solid #eb2028; background-color: #eb2028;'" :elevation="show_mobilization ? 6 : 0">
                  <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan3.png" height="auto"></v-img>
                </v-card>
              </v-hover>

              <v-sheet style="margin: auto;" class="text-center mx-auto pa-3" max-width="1070px">
                <v-expand-transition>
                  <div v-show="show_mobilization">
                    <v-alert
                      elevation="6"
                      style="border: 4px solid #F5F5F5; background-color: #F5F5F5;"
                      class="text-left rounded-xl"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Mobilization</div>
                          <v-row no-gutters>
                            <!-- <div style="width: 4%;"><v-icon size="14" color="#eb2028">mdi-circle</v-icon></div> -->
                            <div style="font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;">Melalui <a target="_blank" href="https://www.instagram.com/trinitylogistik.id/" style="color: black;">Trinity Sarana Logistik.</a></span> Menyediakan layanan mobilisasi kendaraan, truck, dan alat berat</div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row no-gutters align="center" justify="center" class="mt-3">
                        <v-icon size="30px" color="#eb2028" @click="close_mobilization">mdi-close</v-icon>
                      </v-row> 
                    </v-alert>
                  </div>
                </v-expand-transition>
              </v-sheet>
            </v-window-item>

            <v-window-item>
              <v-hover v-slot="{ hover }">
                <!-- <v-card v-model="special_repair" @click="special_repair" width="250" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-2" :color="show_special_repair ? '#eb2028' : hover ? 'grey lighten-1' : 'grey lighten-2'" :elevation="show_special_repair ? 6 : 0"></v-card> -->
                <v-card v-model="special_repair" @click="special_repair" width="250" height="auto" :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-1" :style="show_special_repair ? 'border: 4px solid #eb2028; background-color: #eb2028;' : hover ? 'border: 4px solid #eb2028; background-color: #eb2028;' : 'border: 4px solid #eb2028; background-color: #eb2028;'" :elevation="show_special_repair ? 6 : 0">
                  <v-img :aspect-ratio="4/5.2" src="../assets/layanan/layanan5.png" height="auto"></v-img>
                </v-card>
              </v-hover>

              <v-sheet style="margin: auto;" class="text-center mx-auto pa-3" max-width="1070px">
                <v-expand-transition>
                  <div v-show="show_special_repair">
                    <v-alert
                      elevation="6"
                      style="border: 4px solid #F5F5F5; background-color: #F5F5F5;"
                      class="text-left rounded-xl"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <div style="font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;">Special Repair & Modification</div>
                          <v-row no-gutters>
                            <!-- <div style="width: 4%;"><v-icon size="14" color="#eb2028">mdi-circle</v-icon></div> -->
                            <!-- <div style="font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;">Melalui <a target="_blank" href="https://www.instagram.com/ulin.autogarage/" style="color: black;">ULIN.</a></span> Menyediakan layanan modifikasi, body/underbody protection coating, detailing dan penjualan berbagai aksesoris kendaraan dengan harapan dapat memenuhi keinginan automotive enthusiast</div> -->
                            <div style="font-family: Museo Sans;"><span style="font-family: Museo Sans Bold;">Melalui <a target="_blank" href="https://www.instagram.com/ulin.autogarage/" style="color: black;">ULIN.</a></span> Menyediakan layanan modifikasi, body/underbody protection coating, detailing, dan penjualan berbagai aksesoris kendaraan kendaraan 4WD</div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row no-gutters align="center" justify="center" class="mt-3">
                        <v-icon size="30px" color="#eb2028" @click="close_special_repair">mdi-close</v-icon>
                      </v-row> 
                    </v-alert>
                  </div>
                </v-expand-transition>
              </v-sheet>
            </v-window-item>
          </v-window>

          <v-card-actions class="justify-space-between mt-n4">
            <v-spacer></v-spacer>
            <v-item-group
              v-model="onboarding"
              class="text-center"
              mandatory
            >
              <v-item
                v-for="n in length"
                :key="`btn-${n}`"
                v-slot="{ active, toggle }"
              >
                <v-icon @click="toggle" :input-value="active" :color="active ? '#eb2028' : 'grey lighten-1'">mdi-record</v-icon>
              </v-item>
            </v-item-group>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-col>
    </v-row>
  </div>
</template> 

<script>
  export default {
    name: 'ServiceView',

    data () {
      return {
        show_body_repair: false,
        show_body_fabrikasi: false,
        show_evakuasi: false,
        show_mobilization: false,
        show_special_repair: false,
        model: null,
        length: 5,
        onboarding: 0,
        mobile:null,
        windowSize: {x: 0, y: 0},
        services: [
          { src: require('../assets/layanan/layanan1.png') },
          { src: require('../assets/layanan/layanan2.png') },
          { src: require('../assets/layanan/layanan3.png') },
          { src: require('../assets/layanan/layanan4.png') },
          { src: require('../assets/layanan/layanan5.png') },
        ],
        // services: [
        //   { src: require('../assets/layanan/layanan1.png') , lazy: require('../assets/layanan/lazy/lazy_layanan1.png') },
        //   { src: require('../assets/layanan/layanan2.png') , lazy: require('../assets/layanan/lazy/lazy_layanan2.png') },
        //   { src: require('../assets/layanan/layanan3.png') , lazy: require('../assets/layanan/lazy/lazy_layanan3.png') },
        //   { src: require('../assets/layanan/layanan4.png') , lazy: require('../assets/layanan/lazy/lazy_layanan4.png') },
        //   { src: require('../assets/layanan/layanan5.png') , lazy: require('../assets/layanan/lazy/lazy_layanan5.png') },
        // ],
      }
    },

    watch: {
      windowSize(){
        if (this.windowSize.x < 450) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false  
        }
      },
    },

    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
      },
      body_repair(){
        this.show_body_repair = true
        this.show_body_fabrikasi = false
        this.show_evakuasi = false
        this.show_mobilization = false
        this.show_special_repair = false
      },
      body_fabrikasi(){
        this.show_body_repair = false
        this.show_body_fabrikasi = true
        this.show_evakuasi = false
        this.show_mobilization = false
        this.show_special_repair = false
      },
      evekuasi(){
        this.show_body_repair = false
        this.show_body_fabrikasi = false
        this.show_evakuasi = true
        this.show_mobilization = false
        this.show_special_repair = false
      },
      mobilization(){
        this.show_body_repair = false
        this.show_body_fabrikasi = false
        this.show_evakuasi = false
        this.show_mobilization = true
        this.show_special_repair = false
      },
      special_repair(){
        this.show_body_repair = false
        this.show_body_fabrikasi = false
        this.show_evakuasi = false
        this.show_mobilization = false
        this.show_special_repair = true
      },

      close_body_repair(){
        this.show_body_repair = false
      },
      close_body_fabrikasi(){
        this.show_body_fabrikasi = false
      },
      close_evakuasi(){
        this.show_evakuasi = false
      },
      close_mobilization(){
        this.show_mobilization = false
      },
      close_special_repair(){
        this.show_special_repair = false
      },
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
    },
  }
</script>

<style scoped>

</style>