<template>
  <v-app>
    <AppbarView/>
    <v-main>
      <router-view/>
    </v-main>
    <FooterView/>
  </v-app>
</template>

<script>
import AppbarView from '@/views/AppbarView';
import FooterView from './views/FooterView.vue';

export default {
  name: 'App',
  components: {
    AppbarView, FooterView
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  html {
    scroll-behavior: smooth !important;
  }
  @font-face {
    font-family: "Museo Sans";
    src: local("frontend"),
    url(./fonts/MuseoSans_500.otf) format("opentype");
  }
  @font-face {
    font-family: "Museo Sans Bold";
    src: local("frontend"),
    url(./fonts/MuseoSans_900.otf) format("opentype");
  }
</style>