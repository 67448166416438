<template>
  <div v-resize="onResize">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" lg="5" class="text-left">
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <div :style="mobile ? 'margin: 20px;' : mobile2 ? 'margin-left: 50px; margin-right: 50px;' : 'margin-left: 75px; margin-right: 20px;'">
            <h1 :style="mobile ? 'font-family: Museo Sans Bold; font-size: 30px;' : 'font-family: Museo Sans Bold; font-size: 40px;'">About</h1>
            <div style="font-family: Museo Sans; font-size: 18px;">Lahir tahun 2007 di kota Samarinda, <span class="red--text">TRINITY AUTO</span> terus tumbuh menjadi perusahaan jasa perbaikan yang tidak hanya beroperasi secara regional di Kalimantan, namun mulai mancakup wilayah operasional secara nasional. Pencapaian ini tidak terlepas dari nilai-nilai perusahaan yang ditanamkan secara berkelanjutan untuk melahirkan solusi bisnis terpadu bagi konsumennya.</div>
            <v-btn
              @click="dialog_about_selengkapnya = true"
              dark
              rounded
              height="45"
              depressed
              class="text-capitalize mt-5"
              color="#eb2028"
            >
              <span style="font-family: Museo Sans; font-size: 18px;">Selengkapnya</span>
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="7" class="text-right" v-show="mobile == false">
        <v-img :aspect-ratio="16/9" height="auto" src="../assets/about_dan_company/about_background.png"></v-img>
        
        <!-- <img width="100%" height="auto" loading="lazy" src="../assets/about_dan_company/about_background.png"/> -->
        <!-- <v-img width="100%" height="auto" lazy-src="../assets/about_dan_company/lazy_about_background.png" src="../assets/about_dan_company/about_background.png">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                rounded
                :size="mobile ? '' : '50'"
                :width="mobile ? '' : '5'"
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img> -->
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog_about_selengkapnya"
      v-if="dialog_about_selengkapnya"
      scrollable
      :fullscreen="mobile ? true : false"
      max-width="1100"
      transition="dialog-bottom-transition"
    >
      <v-card style="border-radius: 13px;">
        <v-toolbar flat height="90">
          <v-btn href="#about" v-show="mobile == true" icon class="mr-2" color="#eb2028" @click="dialog_about_selengkapnya = false">
            <v-icon x-large>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title class="ml-n5">
            <div :style="mobile ? 'font-family: Museo Sans Bold; font-size: 30px;' : 'font-family: Museo Sans Bold; font-size: 40px;'">About</div>
            <div style="font-family: Museo Sans; font-size: 18px; margin-top: -13px; margin-bottom: 6px;">Selengkapnya</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn href="#about" x-large v-show="mobile == false" icon color="#eb2028" @click="dialog_about_selengkapnya = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <!-- desktop -->
          <v-sheet>
            <div class="mt-6 text-center">
              <iframe
                :width="mobile ? '100%' : mobile2 ? '70%' : '70%'"
                :height="mobile ? '100%' : mobile2 ? '70%' : '70%'"
                frameborder="0"
                style="top: 0; left: 0; bottom: 0; right: 0; border-radius: 13px; aspect-ratio: 16 / 9;"
                allowfullscreen
                src="https://www.youtube.com/embed/k_4bgzJGeGc"
              ></iframe>
            </div>

            <div class="text-center mx-auto" :style="mobile ? 'width: 100%;' : mobile2 ? 'width: 100%;' : 'width: 90%;'">
              <div style="padding-top: 20px; padding-bottom: 10px;">
                <v-sheet color="#eb2028" rounded="pill" height="38px" width="85px" class="mx-auto">
                  <v-row no-gutters class="fill-height ma-0" align="center" justify="center">
                    <span style="font-family: Museo Sans Bold; font-size: 28px; margin-top: 2.5px; color: white;">VISI</span>
                  </v-row>
                </v-sheet>
              </div>

              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 17px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Sebagai perusahaan jasa perbaikan terpadu <span style="font-family: Museo Sans Bold;">berskala nasional</span> yang terpercaya dan menjadi pilihan utama atas kehandalannya</span></div>
              
              <div :style="mobile ? 'padding-top: 25px; padding-bottom: 10px;' : mobile2 ? 'padding-top: 50px; padding-bottom: 10px;' : 'padding-top: 100px; padding-bottom: 10px;'" style="">
                <v-sheet color="#eb2028" rounded="pill" height="38px" width="85px" class="mx-auto">
                  <v-row no-gutters class="fill-height ma-0" align="center" justify="center">
                    <span style="font-family: Museo Sans Bold; font-size: 28px; margin-top: 2.5px; color: white;">MISI</span>
                  </v-row>
                </v-sheet>
              </div>

              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 18px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu menyediakan solusi bisnis terpadu dalam proses perbaikan maupun pembiayaannya untuk pemangku kepentingan (konsumen, rekan bisnis & perusahaan asuransi)</span></div>
              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 18px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu menjadi panutan yang memegang peran penting dalam keberlanjutan industri layanan perbaikan kendaraan & alat berat</span></div>
              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 18px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu membangun sumber daya manusia terbaik sebagai kunci utama</span></div>
              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 18px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu melakukan peningkatan yang progresif atas aset bisnis untuk merespon perkembangan bisnis yang dinamis</span></div>
              <div v-show="mobile == false && mobile2 == false" :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu melakukan bisnis yang berorientasi pada Keselamatan, Kesehatan & Ramah Lingkungan</span></div>
              <div v-show="mobile == false && mobile2 == false" :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 18px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>(SHE - Safety Health Environment)</span></div>
              <div v-show="mobile == true && mobile2 == true" :style="mobile ? 'margin-bottom: 18px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu melakukan bisnis yang berorientasi pada Keselamatan, Kesehatan & Ramah Lingkungan (SHE - Safety Health Environment)</span></div>

              <!-- <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 17px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu melakukan bisnis yang berorientasi pada Keselamatan, Kesehatan & Ramah Lingkungan Safety Health Environment</span></div>
              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 17px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu menjadi panutan yang memegang peran penting dalam keberlanjutan industri layanan perbaikan kendaraan & alat berat</span></div>
              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 17px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu membangun sumber daya manusia terbaik sebagai kunci utama</span></div>
              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 17px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu melakukan peningkatan yang progresif atas aset bisnis untuk merespon perkembangan bisnis yang dinamis</span></div>
              <div :style="mobile ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : mobile2 ? 'margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;' : 'margin-bottom: 17px; font-family: Museo Sans; font-size: 20px; line-height: 1.8rem;'"><span>Selalu menyediakan solusi bisnis terpadu dalam proses perbaikan maupun pembiayaannya untuk pemangku kepentingan (konsumen, rekan bisnis, & perusahaan asuransi)</span></div> -->
            </div>

            <div style="margin-top: 80px;" class="ml-n6 mr-n6">
              <v-img contain :aspect-ratio="16/10.5" src="../assets/about_dan_company/about_image_nilai_nilai.png"></v-img>
            </div>

            <!-- <div style="margin-top: 80px;" class="ml-n6 mr-n6">
              <div><v-img src="../assets/about_dan_company/about_image_nilai_nilai_01.png"></v-img></div>
              <div><img width="100%" src="../assets/about_dan_company/about_image_nilai_nilai_02.png"/></div>
            </div> -->

            <!-- <div class="text-right" style="margin-bottom: 50px;">
              <div style="font-family: Museo Sans Bold; font-size: 52px; color: #eb2028; line-height: 2.5rem;"><span>NILAI-NILAI</span></div>
              <div style="font-family: Museo Sans Bold; font-size: 35px; color: #eb2028; line-height: 2rem;"><span>PERUSAHAAN</span></div>
              <div class="mr-n6" style="border-top: 5px solid black; width: 280px; float: right;"></div>
            </div> -->

            <div class="text-left" :style="mobile ? 'margin-bottom: 30px; margin-top: 50px;' : mobile2 ? 'margin-bottom: 30px; margin-top: 50px;' : 'margin-bottom: 30px; margin-top: 50px;'">
              <div :style="mobile ? 'font-family: Museo Sans Bold; font-size: 40px; color: #eb2028; line-height: 2rem;' : mobile2 ? 'font-family: Museo Sans Bold; font-size: 40px; color: #eb2028; line-height: 2rem;' : 'font-family: Museo Sans Bold; font-size: 60px; color: #eb2028; line-height: 3rem;'"><span>NILAI-NILAI</span></div>
              <div :style="mobile ? 'font-family: Museo Sans Bold; font-size: 27px; color: #eb2028; line-height: 2rem;' : mobile2 ? 'font-family: Museo Sans Bold; font-size: 27px; color: #eb2028; line-height: 2rem;' : 'font-family: Museo Sans Bold; font-size: 40px; color: #eb2028; line-height: 2.5rem;'"><span>PERUSAHAAN</span></div>
              <div class="ml-n6" :style="mobile ? 'border-top: 5px solid black; width: 220px;' : mobile2 ? '' : 'border-top: 5px solid black; width: 315px;'"></div>
            </div>

            <div :style="mobile ? 'padding-bottom: 30px;' : mobile2 ? 'padding-bottom: 30px; margin-left: 30px; margin-right: 30px;' : 'padding-bottom: 30px; margin-left: 100px; margin-right: 100px;'">
              <div :style="mobile ? 'font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;' : mobile2 ? 'font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;' : 'font-family: Museo Sans Bold; font-size: 22px; color: #eb2028;'"><v-icon size="14px" style="padding-bottom: 3px; padding-right: 3px;" color="#eb2028">mdi-circle</v-icon><span>Professionally Trusted</span></div>
              <div :style="mobile ? 'font-family: Museo Sans; font-size: 16px; color: #eb2028; margin-left: 18px;' : mobile2 ? 'font-family: Museo Sans; font-size: 16px; color: #eb2028; margin-left: 18px;' : 'font-family: Museo Sans; font-size: 18px; color: #eb2028; margin-left: 18px;'"><span>(Profesional Terpercaya)</span></div>
              <div :style="mobile ? 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;' : mobile ? 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;' : 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;'"><span>Memberi rasa aman & nyaman melalui penanganan profesional yang terpercaya & berintegritas adalah tolok ukur dasar kopetensi sumber daya manusia terbaik dari <span style="color: #eb2028;">TRINITY AUTO</span></span></div>
            </div>

            <div :style="mobile ? 'padding-bottom: 30px;' : mobile2 ? 'padding-bottom: 30px; margin-left: 30px; margin-right: 30px;' : 'padding-bottom: 30px; margin-left: 100px; margin-right: 100px;'">
              <div :style="mobile ? 'font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;' : mobile2 ? 'font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;' : 'font-family: Museo Sans Bold; font-size: 22px; color: #eb2028;'"><v-icon size="14px" style="padding-bottom: 3px; padding-right: 3px;" color="#eb2028">mdi-circle</v-icon><span>Excellence</span></div>
              <div :style="mobile ? 'font-family: Museo Sans; font-size: 16px; color: #eb2028; margin-left: 18px;' : mobile2 ? 'font-family: Museo Sans; font-size: 16px; color: #eb2028; margin-left: 18px;' : 'font-family: Museo Sans; font-size: 18px; color: #eb2028; margin-left: 18px;'"><span>(Unggul)</span></div>
              <div :style="mobile ? 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;' : mobile ? 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;' : 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;'"><span>Berorientasi pada proses pengerjaan terbaik & kecepatan pengelolaan sumber daya secara efektif yang dibungkus oleh tanggung jawab dalam memberikan solusi terbaik</span></div>
            </div>

            <div :style="mobile ? 'padding-bottom: 30px;' : mobile2 ? 'padding-bottom: 30px; margin-left: 30px; margin-right: 30px;' : 'padding-bottom: 30px; margin-left: 100px; margin-right: 100px;'">
              <div :style="mobile ? 'font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;' : mobile2 ? 'font-family: Museo Sans Bold; font-size: 20px; color: #eb2028;' : 'font-family: Museo Sans Bold; font-size: 22px; color: #eb2028;'"><v-icon size="14px" style="padding-bottom: 3px; padding-right: 3px;" color="#eb2028">mdi-circle</v-icon><span>Continuous Improvement</span></div>
              <div :style="mobile ? 'font-family: Museo Sans; font-size: 16px; color: #eb2028; margin-left: 18px;' : mobile2 ? 'font-family: Museo Sans; font-size: 16px; color: #eb2028; margin-left: 18px;' : 'font-family: Museo Sans; font-size: 18px; color: #eb2028; margin-left: 18px;'"><span>(Peningkatan yang Berkelanjutan)</span></div>
              <div :style="mobile ? 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;' : mobile ? 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;' : 'font-family: Museo Sans; font-size: 16px; text-align: justify; margin-left: 18px;'"><span>Kemampuan beradaptasi, keterbukaan terhadap hal baru & kemampuan merespon dengan cepat terhadap lingkungan bisnis yang selalu berubah untuk tetap melakukan peningkatan performa bisnis berkelanjutan</span></div>
            </div>

            <!-- <div style="margin-bottom: 25px; margin-top: 50px;">
              <div style="font-family: Museo Sans Bold; font-size: 30px; text-align: right;">
                <div><img :style="mobile ? 'margin: auto; width: 100%; margin-bottom: -20px' : mobile2 ? 'margin: auto; width: 80%; margin-bottom: -20px' : 'margin: auto; width: 55%; margin-bottom: -40px; margin-right: 20%;'" src="../assets/about_dan_company/man_image.png"/></div>
                <div :style="mobile ? 'font-family: Museo Sans Bold; font-size: 40px; color: #4a4f55; margin-right: 25px;' : mobile2 ? 'font-family: Museo Sans Bold; font-size: 40px; color: #4a4f55;' : 'font-family: Museo Sans Bold; font-size: 60px; line-height: 2rem; color: #4a4f55; padding-right: 44px;'"><span>SUMBER DAYA</span></div>
                <div :style="mobile ? 'font-family: Museo Sans Bold; font-size: 40px; color: #4a4f55;' : mobile2 ? 'font-family: Museo Sans Bold; font-size: 40px; color: #4a4f55;' : 'font-family: Museo Sans Bold; font-size: 60px; color: #4a4f55;'"><span>MANUSIA</span><span :style="mobile ? 'font-size: 90px; color: #eb2028;' : mobile2 ? 'font-size: 100px; color: #eb2028;' : 'font-size: 150px; color: #eb2028;'">.</span></div>
              </div>
            </div>

            <div :style="mobile ? 'width: 100%; margin: auto;' : mobile2 ? 'width: 100%; margin: auto;' : 'width: 80%; margin: auto;'">
              <div :style="mobile ? 'font-family: Museo Sans; font-size: 16px; line-height: 1.5rem; text-align: justify;' : mobile2 ? 'font-family: Museo Sans; font-size: 16px; line-height: 1.5rem; text-align: justify;' : 'font-family: Museo Sans; font-size: 18px; line-height: 1.5rem; text-align: justify;'"><span>Dinamika bisnis yang terus berubah menjadi tantangan yang harus dijawab oleh kemampuan terbaik sumber daya manusia <span style="color: #eb2028">TRINITY AUTO</span> dengan mengedepankan nilai <span style="font-family: Museo Sans Bold;">Profesionalisme, Integritas, Efektivitas & Efisieni</span></span></div>
            </div> -->






            <!-- <div style="text-align: center;">
              <v-img style="width: 500px; height: auto;" src="../assets/about_dan_company/man_image.png"/>
              <div style="width: 100%; font-family: Museo Sans Bold; font-size: 30px;">
                <div style="font-family: Museo Sans Bold; font-size: 30px;">
                  <div style="padding-right: ">
                    <div style="text-align: right; font-family: Museo Sans Bold; font-size: 60px; margin-bottom: 30px;"><span>SUMBER DAYA</span></div>
                    <div style="text-align: right; font-family: Museo Sans Bold; font-size: 60px;"><span>MANUSIA</span></div>
                  </div>
                </div>
              </div>
            </div> -->

            
          </v-sheet>

          <!-- mobile -->
          <!-- <v-sheet v-show="mobile == true">
            <div class="mt-6 text-center">
              <iframe
                :width="mobile ? '100%' : mobile2 ? '100%' : '70%'"
                :height="mobile ? '100%' : mobile2 ? '100%' : '70%'"
                frameborder="0"
                style="top: 0; left: 0; bottom: 0; right: 0; border-radius: 13px; aspect-ratio: 16 / 9;"
                allowfullscreen
                src="https://www.youtube.com/embed/k_4bgzJGeGc"
              ></iframe>
            </div>

            <div class="text-center mx-auto" style="width: 100%;">
              <div style="padding-top: 20px; padding-bottom: 10px;">
                <v-sheet color="#eb2028" rounded="pill" height="35px" width="72px" class="mx-auto">
                  <v-row no-gutters class="fill-height ma-0" align="center" justify="center">
                    <span style="font-family: Museo Sans Bold; font-size: 22px; margin-top: 2.5px; color: white;">VISI</span>
                  </v-row>
                </v-sheet>
              </div>
              
              <div style="font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;"><span>Sebagai perusahaan jasa perbaikan terpadu berskala yang terpercaya dan menjadi pilihan utama atas kehandalannya</span></div>
              <div style="padding-top: 80px; padding-bottom: 10px;">
                <v-sheet color="#eb2028" rounded="pill" height="35px" width="72px" class="mx-auto">
                  <v-row no-gutters class="fill-height ma-0" align="center" justify="center">
                    <span style="font-family: Museo Sans Bold; font-size: 22px; margin-top: 2.5px; color: white;">MISI</span>
                  </v-row>
                </v-sheet>
              </div>
              
              <div style="margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;"><span>Selalu melakaukan bisnis yang berorientasi pada Keselamatan, Kesehatan & Ramah Lingkungan Safety Health Environment</span></div>
              <div style="margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;"><span>Selalu menjadi panutan yang memegang peran penting dalam keberlanjutan industri layanan perbaikan kendaraan & alat berat</span></div>
              <div style="margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;"><span>Selalu membangun sumber daya manusia terbaik sebagai kunci utama</span></div>
              <div style="margin-bottom: 17px; font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;"><span>Selalu melakukan peningkatan yang progresif atas aset bisnis untuk merespon perkembangan bisnis yang dinamis</span></div>
              <div style="font-family: Museo Sans; font-size: 18px; line-height: 1.5rem;"><span>Selalu menyediakan solusi bisnis terpadu dalam proses perbaikan maupun pembiayaannya untuk pemangku kepentingan (konsumen, rekan bisnis, & perusahaan asuransi)</span></div>
            </div>

            <div style="margin-top: 80px; margin-bottom: 20px;" class="ml-n6 mr-n6">
                <div><img width="100%" src="../assets/about_dan_company/about_image_nilai_nilai.png"/></div>
              </div>

              <div class="text-right">
                <div style="font-family: Museo Sans Bold; font-size: 35px; color: #eb2028; line-height: 1.5rem;"><span>NILAI-NILAI</span></div>
                <div style="font-family: Museo Sans Bold; font-size: 18px; color: #eb2028; line-height: 1.5rem;"><span>PERUSAHAAN</span></div>
                <div class="mr-n6" style="border-top: 4px solid black; width: 162px; float: right;"></div>
              </div>

              <div style="padding-top: 50px; padding-bottom: 30px;">
                <div style="font-family: Museo Sans Bold; font-size: 22px; color: #eb2028; margin-left: -19px;"><v-icon size="16px" style="padding-bottom: 3px; padding-right: 3px;" color="#eb2028">mdi-circle</v-icon><span>Professionally Trusted</span></div>
                <div style="font-family: Museo Sans; font-size: 18px; color: #eb2028;"><span>(Profesional Terpercaya)</span></div>
                <div style="font-family: Museo Sans; font-size: 16px; text-align: justify;"><span>Memberi rasa aman & nyaman melalui penanganan profesional yang terpercaya & berintegritas adalah tolok ukur dasar kopetensi sumber daya manusia terbaik dari <span style="color: #eb2028;">TRINITY AUTO</span></span></div>
              </div>

              <div style="padding-bottom: 30px;">
                <div style="font-family: Museo Sans Bold; font-size: 22px; color: #eb2028; margin-left: -19px;"><v-icon size="16px" style="padding-bottom: 3px; padding-right: 3px;" color="#eb2028">mdi-circle</v-icon><span>Excellence</span></div>
                <div style="font-family: Museo Sans; font-size: 18px; color: #eb2028;"><span>(Unggul)</span></div>
                <div style="font-family: Museo Sans; font-size: 16px; text-align: justify;"><span>Berorientasi pada proses pengerjaan terbaik & kecepatan pengelolaan sumber daya secara efektif yang dibungkus oleh tanggung jawab dalam memberikan solusi terbaik</span></div>
              </div>

              <div style="padding-bottom: 30px;">
                <div style="font-family: Museo Sans Bold; font-size: 22px; color: #eb2028; margin-left: -19px;"><v-icon size="16px" style="padding-bottom: 3px; padding-right: 3px;" color="#eb2028">mdi-circle</v-icon><span>Continuous Improvement</span></div>
                <div style="font-family: Museo Sans; font-size: 18px; color: #eb2028;"><span>(Peningkatan yang Berkelanjutan)</span></div>
                <div style="font-family: Museo Sans; font-size: 16px; text-align: justify;"><span>Kemampuan beradaptasi, keterbukaan terhadap hal baru & kemampuan merespon dengan cepat terhadap lingkungan bisnis yang selalu berubah untuk tetap melakukan peningkatan performa bisnis berkelanjutan</span></div>
              </div>

              <div style="margin-bottom: 10px; margin-top: 50px;">
                <div style="font-family: Museo Sans Bold; font-size: 30px; text-align: right;">
                  <div><img style="margin: auto; margin-bottom: -30px;" width="100%" src="../assets/about_dan_company/man_image.png"/></div>
                  <div style="margin-bottom: -10px; margin-right: 20px;"><span>SUMBER DAYA</span></div>
                  <div><span>MANUSIA</span><span style="font-size: 70px; color: #eb2028;">.</span></div>
                </div>
              </div>

              <div style="width: 100%; margin: auto;">
              <div style="font-family: Museo Sans; font-size: 16px; line-height: 1.5rem; text-align: justify;"><span>Dinamika bisnis yang terus berubah menjadi tantangan yang harus dijawab oleh kemampuan terbaik sumber daya manusia <span style="color: #eb2028">TRINITY AUTO</span> dengan mengedepankan nilai <span style="font-family: Museo Sans Bold;">Profesional, Integritas, Efektivitas & Efisieni</span></span></div>
            </div>
          </v-sheet> -->
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AboutView",

  data() {
    return {
      dialog_about_selengkapnya: false,
      mobile: null,
      mobile2: null,
      windowSize: {x: 0, y: 0},
      windowSize2: {x: 0, y: 0},
      youtubeLink:
        "https://www.youtube.com/watch?v=7dvbx0kqjJ8&t=9s&ab_channel=TrinityAuto",
    };
  },

  mounted() {
    // this.$refs['lazyVideo'].playVideo();
  },
  watch: {
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false  
      }
    },

    windowSize2(){
      if (this.windowSize2.x < 600) {
        this.titleClass = "d-none"
        this.mobile2 = true
      }else{
        this.titleClass = "mr-4"
        this.mobile2 = false  
      }
    },
  },

  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.windowSize2 = { x: window.innerWidth, y: window.innerHeight }
    },
  },
};
</script>

<style>
  .responsive-iframe {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>