<template>
  <div v-resize="onResize" class="main" id="contact" :style="mobile ? 'padding-top: 10px;' : iPad ? 'padding-top: 80px;' : GalaxyFold ? 'padding-top: 20px;' : 'padding-top: 70px;'">
    <v-footer
      padless
      color="#eb2028"
    >
      <v-sheet style="width: 100%;">
        <v-row no-gutters>
          <v-col cols="12" sm="5" md="6" lg="4" class="text-left">
            <div style="margin-left: 75px;" class="ma-5">
              <h1 style="font-family: Museo Sans Bold;">PT. Trinity Auto</h1>
              <div style="font-family: Museo Sans;">Jl. Bung Tomo No.49, Sungai keledang, kec. Samarinda Seberang, Kota Samarinda, Kalimantan Timur, Indonesia <span class="red--text">75131</span></div>
              <div style="font-family: Museo Sans;">Phone <a style="color: #eb2028;" href="tel:+62 541 262 800">+62 541 262 800</a></div>
              <v-img :class="mobile ? 'mt-5 mx-auto' : 'mt-5'" width="300" height="auto" src="../assets/TA_Logo.png"></v-img>
            </div>
          </v-col>

          <v-col cols="12" sm="4" md="6" lg="4" class="text-center mx-auto">
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-card outlined class="ml-6 mt-6 mb-6 mr-6 rounded-xl elevation-0" max-width="500">
                <iframe class="mb-n2" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1994.8286648881883!2d117.12977283811256!3d-0.5147101649389648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2df67fb049ebe3df%3A0x63e44e9e5816eb78!2sTrinity%20Auto!5e0!3m2!1sid!2sid!4v1716290548208!5m2!1sid!2sid" height="250" :width="mobile ? '350' : iPad ? '360' : GalaxyFold ? '100' : GalaxyS20Ultra ? '400' : '370'" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <!-- <iframe
                  class="mb-n2"
                  :width="mobile ? '350' : iPad ? '360' : GalaxyFold ? '100' : GalaxyS20Ultra ? '400' : '370'"
                  height="250"
                  src="https://www.google.com/maps/place/Trinity+Auto/@-0.5144554,117.1289735,17z/data=!4m14!1m7!3m6!1s0x2df67fb049ebe3df:0x63e44e9e5816eb78!2sTrinity+Auto!8m2!3d-0.5145251!4d117.130261!16s%2Fg%2F1hd_5lxq2!3m5!1s0x2df67fb049ebe3df:0x63e44e9e5816eb78!8m2!3d-0.5145251!4d117.130261!16s%2Fg%2F1hd_5lxq2?entry=ttu;output=embed"
                  frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> -->
              </v-card>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="4" class="text-left">
            <v-row
              class="fill-height ma-0"
              align="end"
              justify="center"
            >
              <div class="ma-5">
                <v-row no-gutters>
                  <v-btn
                    v-for="(item, i) in Sosial"
                    :key="i"
                    :href="item.link"
                    small
                    depressed
                    class="mx-2 grey darken-1"
                    style="border-radius: 15px;"
                    height="50"
                    width="50"
                    target="_blank"
                  >
                    <v-icon color="white" size="40px">
                      {{ item.icon }}
                    </v-icon>
                  </v-btn>
                </v-row>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet style="width: 100%;" color="#eb2028" height="60">
        <v-img src="../assets/super_grafis.png" height="60" width="auto">
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <span style="font-family: Museo Sans; font-size: 14px;" class="white--text">© {{ new Date().getFullYear() }} Trinity Auto</span>
          </v-row>
        </v-img>
      </v-sheet>
    </v-footer>
  </div>
</template> 

<script>
  export default {
    name: 'FooterView',

    data () {
      return {
        mobile: null,
        iPad: null,
        GalaxyFold: null,
        GalaxyS20Ultra: null,
        windowSize: {x: 0, y: 0},
        windowSizeiPad: {x: 0, y: 0},
        windowSizeGalaxyFold: {x: 0, y: 0},
        windowSizeGalaxyS20Ultra: {x: 0, y: 0},
        Sosial: [
          {icon: 'mdi-instagram', link: 'https://www.instagram.com/trinityauto.id/?hl=id'},
          {icon: 'mdi-facebook', link: 'https://www.facebook.com/trinityauto.id/'},
          {icon: 'mdi-whatsapp', link: 'https://wa.me/6282154217777?text=Halo'},
          {icon: 'mdi-linkedin', link: 'https://id.linkedin.com/company/trinityauto'},
        ],
      }
    },

    watch: {
      windowSize(){
        if (this.windowSize.x < 450) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false  
        }
      },

      windowSizeiPad(){
        if (this.windowSizeiPad.x < 1000) {
          this.titleClass = "d-none"
          this.iPad = true
        }else{
          this.titleClass = "mr-4"
          this.iPad = false  
        }
      },

      windowSizeGalaxyFold(){
        if (this.windowSizeGalaxyFold.x < 300) {
          this.titleClass = "d-none"
          this.GalaxyFold = true
        }else{
          this.titleClass = "mr-4"
          this.GalaxyFold = false  
        }
      },

      windowSizeGalaxyS20Ultra(){
        if (this.windowSizeGalaxyS20Ultra.x < 412) {
          this.titleClass = "d-none"
          this.GalaxyS20Ultra = true
        }else{
          this.titleClass = "mr-4"
          this.GalaxyS20Ultra = false  
        }
      },
    },

    methods: {
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        this.windowSizeiPad= { x: window.innerWidth, y: window.innerHeight }
        this.windowSize3= { x: window.innerWidth, y: window.innerHeight }
      },
    },
  }
</script>