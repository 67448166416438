<template>
  <div v-resize="onResize" class="main" :style="mobile ? '' : 'margin-top: -130px;'">
    <div class="main" id="#"><Banner/></div>
    <div :style="mobile ? 'padding-top: 60px;' : iPad ? 'padding-top: 110px;' : 'padding-top: 110px;'" class="main" id="about"><About/></div>
    <div :style="mobile ? 'padding-top: 60px;' : iPad ? 'padding-top: 110px;' : 'padding-top: 110px;'" class="main" id="service"><Service/></div>
    <div :style="mobile ? 'padding-top: 75px;' : iPad ? 'padding-top: 110px;' : 'padding-top: 110px;'" class="main" id="workshop"><Workshop/></div>
    <div :style="mobile ? 'padding-top: 60px; padding-bottom: 50px;' : 'padding-top: 90px;'" class="main" id="client"><Client/></div>

    <v-fab-transition v-show="mobile == false">
      <v-btn
        color="#eb2028" class="elevation-0" style="border: 6px solid;"
        v-scroll="onScroll"
        v-show="scroll_keatas && mobile == false"
        fab
        dark
        fixed
        bottom
        right
        @click="toTop"
      >
        <v-icon>mdi-arrow-up-thick</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script> 
  import Banner from './BannerView.vue';
  import About from './AboutView.vue';
  import Service from './ServiceView.vue';
  import Client from './ClientView.vue';
import Workshop from './WorkshopView.vue';
  export default {
    name: 'HomeView',
    components: {
      Banner, About, Service, Client, Workshop
    },

    data () {
      return {
        scroll_keatas: false,
        mobile:null,
        iPad: null,
        windowSize: {x: 0, y: 0},
        windowSizeipad: {x: 0, y: 0},
        length_delimiters: 5,
        banner: 0,
        slides_banner: [
          { src: require('../assets/banner/banner_1.png')},
          { src: require('../assets/banner/banner_2.png')},
          { src: require('../assets/banner/banner_3.png')},
          { src: require('../assets/banner/banner_4.png')},
          { src: require('../assets/banner/banner_5.png')},
        ],
        Sosial: [
          {icon: 'mdi-instagram', link: 'https://www.instagram.com/trinityauto.id/?hl=id'},
          {icon: 'mdi-facebook', link: 'https://www.facebook.com/trinityauto.id/'},
          {icon: 'mdi-whatsapp', link: 'https://wa.me/6282154217777?text=Halo'},
          {icon: 'mdi-linkedin', link: 'https://id.linkedin.com/company/trinityauto'},
        ],
      }
    },

    watch: {
      windowSize(){
        if (this.windowSize.x < 450) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false  
        }
      },

      windowSizeipad(){
        if (this.windowSizeipad.x < 1000) {
          this.titleClass = "d-none"
          this.iPad = true
        }else{
          this.titleClass = "mr-4"
          this.iPad = false  
        }
      },
    },

    methods: {
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        this.windowSizeipad = { x: window.innerWidth, y: window.innerHeight }
      },

      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.scroll_keatas = top > 20
      },

      toTop () {
        this.$vuetify.goTo(0)
      },
    },
  }
</script>
