<template>
  <div v-resize="onResize">
    <v-app-bar
      app
      flat
      fixed
      :height="phone ? '80px' : tablet ? '80px' : laptop ? '90' : desktop ? '110px' : '110px'"
      style="background: linear-gradient(white, transparent)"
    >
      <a href="#">
        <v-img
          src="../assets/TA_Logo.png"
          :style="phone ? '' : tablet ? 'margin-left: 5px;' : laptop ? 'margin-left: 80px;' : desktop ? 'margin-left: 80px;' : 'margin-left: 80px;'"
          :max-width="phone ? '130px' : tablet ? '140px' : laptop ? '220px' : desktop ? '250px' : '250px'"
          height="auto"
        ></v-img>
      </a>

      <v-spacer></v-spacer>

      <v-menu offset-y :close-on-content-click="false" v-model="menu_mode_phone" rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="phone == true" icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon x-large color="#eb2028">{{ menu_mode_phone ? 'mdi-close' : 'mdi-menu' }}</v-icon>
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-list flat>
            <v-list-item-group mandatory color="red">
              <v-list-item href="#">
                <v-list-item-title style="font-family: Museo Sans; font-size: 18px;">Home</v-list-item-title>
              </v-list-item>
              <v-list-item href="#about">
                <v-list-item-title style="font-family: Museo Sans; font-size: 18px;">About</v-list-item-title>
              </v-list-item>
              <v-list-item href="#service">
                <v-list-item-title style="font-family: Museo Sans; font-size: 18px;">Service</v-list-item-title>
              </v-list-item>
              <v-list-item href="#workshop">
                <v-list-item-title style="font-family: Museo Sans; font-size: 18px;">Workshop</v-list-item-title>
              </v-list-item>
              <v-list-item href="#client">
                <v-list-item-title style="font-family: Museo Sans; font-size: 18px;">Partner</v-list-item-title>
              </v-list-item>
              <v-list-item @click="dialog_media_sosial = true">
                <v-list-item-title style="font-family: Museo Sans; font-size: 18px;">Contact</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item href="https://docs.google.com/forms/d/e/1FAIpQLSfVAHOq_WAGoXO5DXA9rX8vt3_m5FbcH5Km8uCIj3qXT82atg/viewform" target="_blank">
                <v-list-item-title style="font-family: Museo Sans; font-size: 18px;">Career</v-list-item-title>
              </v-list-item> -->
              <v-list-item href="https://trinity.web.id/jobs" target="_blank">
                <v-list-item-title style="font-family: Museo Sans; font-size: 18px;">Career</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

      <v-chip-group
        v-show="phone == false"
        mandatory
        active-class="red--text"
        :style="phone ? '' : tablet ? '' : laptop ? '' : desktop ? 'margin-right: 60px;' : 'margin-right: 60px;'"
      >
        <v-chip
          href="#"
          :small="phone ? '' : tablet ? true : laptop ? true : desktop ? false : false"
          :style="phone ? 'font-family: Museo Sans; font-size: 14px;' : tablet ? 'font-family: Museo Sans; font-size: 15px;' : laptop ? 'font-family: Museo Sans; font-size: 16px;' : desktop ? 'font-family: Museo Sans; font-size: 18px;' : 'font-family: Museo Sans; font-size: 18px;'"
          color="transparent"
        >Home</v-chip>
        <v-chip
          href="#about"
          :small="phone ? '' : tablet ? true : laptop ? true : desktop ? false : false"
          :style="phone ? 'font-family: Museo Sans; font-size: 14px;' : tablet ? 'font-family: Museo Sans; font-size: 15px;' : laptop ? 'font-family: Museo Sans; font-size: 16px;' : desktop ? 'font-family: Museo Sans; font-size: 18px;' : 'font-family: Museo Sans; font-size: 18px;'"
          color="transparent"
        >About</v-chip>
        <v-chip
          href="#service"
          :small="phone ? '' : tablet ? true : laptop ? true : desktop ? false : false"
          :style="phone ? 'font-family: Museo Sans; font-size: 14px;' : tablet ? 'font-family: Museo Sans; font-size: 15px;' : laptop ? 'font-family: Museo Sans; font-size: 16px;' : desktop ? 'font-family: Museo Sans; font-size: 18px;' : 'font-family: Museo Sans; font-size: 18px;'"
          color="transparent"
        >Service</v-chip>
        <v-chip
          href="#workshop"
          :small="phone ? '' : tablet ? true : laptop ? true : desktop ? false : false"
          :style="phone ? 'font-family: Museo Sans; font-size: 14px;' : tablet ? 'font-family: Museo Sans; font-size: 15px;' : laptop ? 'font-family: Museo Sans; font-size: 16px;' : desktop ? 'font-family: Museo Sans; font-size: 18px;' : 'font-family: Museo Sans; font-size: 18px;'"
          color="transparent">Workshop</v-chip>
        <v-chip
          href="#client"
          :small="phone ? '' : tablet ? true : laptop ? true : desktop ? false : false"
          :style="phone ? 'font-family: Museo Sans; font-size: 14px;' : tablet ? 'font-family: Museo Sans; font-size: 15px;' : laptop ? 'font-family: Museo Sans; font-size: 16px;' : desktop ? 'font-family: Museo Sans; font-size: 18px;' : 'font-family: Museo Sans; font-size: 18px;'"
          color="transparent"
        >Partner</v-chip>
        <v-menu offset-y rounded="xl" transition="slide-y-transition" bottom v-model="menu_contact_sosial">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              color="transparent"
              :small="phone ? '' : tablet ? true : laptop ? true : desktop ? false : false"
              :style="phone ? 'font-family: Museo Sans; font-size: 14px;' : tablet ? 'font-family: Museo Sans; font-size: 15px;' : laptop ? 'font-family: Museo Sans; font-size: 16px;' : desktop ? 'font-family: Museo Sans; font-size: 18px;' : 'font-family: Museo Sans; font-size: 18px;'"
            >
              Contact
            </v-chip>
          </template>
          <v-card>
            <v-list flat>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in list_menu_contact_sosial"
                  :key="i"
                  :href="item.link"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-sheet
                      class="grey darken-1"
                      style="border-radius: 15px; margin-right: -15px"
                      height="50"
                      width="50"
                    >
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                        <v-icon class="mx-auto" color="white" size="40px" v-text="item.icon"></v-icon> 
                      </v-row>
                    </v-sheet>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title style="font-family: Museo Sans; font-size: 18px;" v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <!-- <v-chip
          href="https://docs.google.com/forms/d/e/1FAIpQLSfVAHOq_WAGoXO5DXA9rX8vt3_m5FbcH5Km8uCIj3qXT82atg/viewform"
          :small="phone ? '' : tablet ? true : laptop ? true : desktop ? false : false"
          :style="phone ? 'font-family: Museo Sans; font-size: 14px;' : tablet ? 'font-family: Museo Sans; font-size: 15px;' : laptop ? 'font-family: Museo Sans; font-size: 16px;' : desktop ? 'font-family: Museo Sans; font-size: 18px;' : 'font-family: Museo Sans; font-size: 18px;'"
          color="transparent" target="_blank"
        >Career</v-chip> -->
        <v-chip
          href="https://trinity.web.id/jobs"
          :small="phone ? '' : tablet ? true : laptop ? true : desktop ? false : false"
          :style="phone ? 'font-family: Museo Sans; font-size: 14px;' : tablet ? 'font-family: Museo Sans; font-size: 15px;' : laptop ? 'font-family: Museo Sans; font-size: 16px;' : desktop ? 'font-family: Museo Sans; font-size: 18px;' : 'font-family: Museo Sans; font-size: 18px;'"
          color="transparent" target="_blank"
        >Career</v-chip>
      </v-chip-group>

      <v-dialog
        v-model="dialog_media_sosial"
        max-width="200"
      >
        <v-card class="rounded-xl">
          <v-list flat>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in list_menu_contact_sosial"
                :key="i"
                :href="item.link"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-sheet
                    class="grey darken-1"
                    style="border-radius: 15px; margin-right: -15px"
                    height="50"
                    width="50"
                  >
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                      <v-icon class="mx-auto" color="white" size="40px" v-text="item.icon"></v-icon> 
                    </v-row>
                  </v-sheet>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="font-family: Museo Sans; font-size: 18px;" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    name: 'AppbarView',

    data () {
      return {
        phone: null,
        tablet: null,
        laptop: null,
        desktop: null,
        window_size_mobile: {x: 0, y: 0},
        window_size_tablet: {x: 0, y: 0},
        window_size_laptop: {x: 0, y: 0},
        dialog_media_sosial: false,
        menu_contact_sosial: 0,
        menu_mode_phone: null,
        list_menu_contact_sosial: [
          {icon: 'mdi-instagram', text: 'Instagram', link: 'https://www.instagram.com/trinityauto.id/?hl=id'},
          {icon: 'mdi-facebook', text: 'facebook', link: 'https://www.facebook.com/trinityauto.id/'},
          {icon: 'mdi-whatsapp', text: 'WhatsApp', link: 'https://wa.me/6282154217777?text=Halo'},
          {icon: 'mdi-linkedin', text: 'Linkedin', link: 'https://id.linkedin.com/company/trinityauto'},
        ],
      }
    },

    watch: {
      window_size_mobile(){
        if (this.window_size_mobile.x < 600) {
          this.phone = true
        }else{
          this.phone = false  
        }
      },

      window_size_tablet(){
        if (this.window_size_tablet.x < 960) {
          this.tablet = true
        }else{
          this.tablet = false  
        }
      },

      window_size_laptop(){
        if (this.window_size_laptop.x < 1264) {
          this.laptop = true
        }else{
          this.laptop = false  
        }
      },

      window_size_desktop(){
        if (this.window_size_desktop.x < 1904) {
          this.desktop = true
        }else{
          this.desktop = false  
        }
      },
    },

    methods: {
      onResize(){
        this.window_size_mobile = { x: window.innerWidth, y: window.innerHeight }
        this.window_size_tablet = { x: window.innerWidth, y: window.innerHeight }
        this.window_size_laptop = { x: window.innerWidth, y: window.innerHeight }
        this.window_size_desktop = { x: window.innerWidth, y: window.innerHeight }
      },
    },
  }
</script>