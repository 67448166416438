<template>
  <div v-resize="onResize">
    <v-carousel
      interval="5000" 
      :cycle="true"
      hide-delimiters
      v-model="banner"
      :height="mobile ? 'auto' : 'auto'"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <template v-slot:prev="{ on, attrs }">
        <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
          <v-btn
            fab
            :small="mobile ? true : false"
            depressed
            color="#eb2028"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :large="mobile ? false : true">mdi-arrow-left-thick</v-icon>
          </v-btn>
        </v-sheet>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
          <v-btn
            fab
            :small="mobile ? true : false"
            depressed
            color="#eb2028"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :large="mobile ? false : true">mdi-arrow-right-thick</v-icon>
          </v-btn>
        </v-sheet>
      </template>

      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
      >
        <img style="width: 100%; aspect-ratio: 16 / 8;" loading="lazy" :src="slide.src"/>
        <!-- <v-img
          aspect-ratio="1.9"
          contain
          width="100%"
          :src="slide.src"
          :lazy-src="slide.lazy"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                rounded
                :size="mobile ? '' : '70'"
                :width="mobile ? '' : '8'"
                color="grey"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img> -->
      </v-carousel-item>
      <v-item-group
        v-model="banner"
        :class="mobile ? '' : 'text-left'"
        :style="mobile ? 'margin-left: 20px;' : 'margin-top: -100px; margin-bottom: 90px; margin-left: 75px;'"
        mandatory
      >
        <v-item
          v-for="slide in length"
          :key="slide"
          v-slot="{ active, toggle }"
        >
          <v-icon @click="toggle" :input-value="active" :color="active ? '#eb2028' : 'grey lighten-1'">mdi-record</v-icon>
        </v-item>
      </v-item-group>
    </v-carousel> 
  </div>
</template> 

<script>
  export default {
    name: 'BannerView',

    data () {
      return {
        mobile:null,
        windowSize: {x: 0, y: 0},
        length: 5,
        banner: 0,
        slides: [
          { src: require('../assets/banner/banner_1.png') },
          { src: require('../assets/banner/banner_2.png') },
          { src: require('../assets/banner/banner_3.png') },
          { src: require('../assets/banner/banner_4.png') },
          { src: require('../assets/banner/banner_5.png') },
        ],
        // slides: [
        //   { src: require('../assets/banner/banner_1.png'), lazy: require('../assets/banner/lazy/lazy_banner_1.png'), },
        //   { src: require('../assets/banner/banner_2.png'), lazy: require('../assets/banner/lazy/lazy_banner_2.png'),},
        //   { src: require('../assets/banner/banner_3.png'), lazy: require('../assets/banner/lazy/lazy_banner_3.png'),},
        //   { src: require('../assets/banner/banner_4.png'), lazy: require('../assets/banner/lazy/lazy_banner_4.png'),},
        //   { src: require('../assets/banner/banner_5.png'), lazy: require('../assets/banner/lazy/lazy_banner_5.png'),},
        // ],

      }
    },

    watch: {
      windowSize(){
        if (this.windowSize.x < 450) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false  
        }
      },
    },

    methods: {
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
    },
  }
</script>