import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
import About from '../views/AboutView.vue'
import Service from '../views/ServiceView.vue'
import Client from '../views/ClientView.vue'
import Workshop from '../views/WorkshopView.vue'
import Banner from '../views/BannerView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Banner',
    name: 'Banner',
    component: Banner
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/Service',
    name: 'Service',
    component: Service
  },
  {
    path: '/Client',
    name: 'Client',
    component: Client
  },
  {
    path: '/Workshop',
    name: 'Workshop',
    component: Workshop
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
