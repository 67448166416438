<template>
  <div v-resize="onResize">
    <!-- desktop -->
    <v-row v-show="mobile == false" no-gutters justify="center" class="mt-3">
      <v-col cols="12" sm="10" md="8" lg="8">
        <v-item-group class="text-center" mandatory>
          <v-item>
            <v-btn v-model="btn_client_desktop" @click="btn_client_desktop" class="ma-2 text-capitalize" depressed rounded width="30%" height="40px" :color="show_client_desktop ? '#eb2028' : 'grey lighten-2'">
              <span :style="show_client_desktop ? 'color: white; font-family: Museo Sans; font-size: 16px;' : 'font-family: Museo Sans; font-size: 16px;'">Client</span>
            </v-btn>
          </v-item>
          <v-item>
            <v-btn v-model="btn_vendor_desktop" @click="btn_vendor_desktop" class="ma-2 text-capitalize" depressed width="30%" rounded height="40px" :color="show_vendor_desktop ? '#eb2028' : 'grey lighten-2'">
              <span :style="show_vendor_desktop ? 'color: white; font-family: Museo Sans; font-size: 16px;' : 'font-family: Museo Sans; font-size: 16px;'">Vendor</span>
            </v-btn>
          </v-item>
          <v-item>
            <v-btn v-model="btn_insurance_partner_desktop" @click="btn_insurance_partner_desktop" class="ma-2 text-capitalize" rounded depressed width="30%" height="40px" :color="show_insurance_partner_desktop ? '#eb2028' : 'grey lighten-2'">
              <span :style="show_insurance_partner_desktop ? 'color: white; font-family: Museo Sans; font-size: 16px;' : 'font-family: Museo Sans; font-size: 16px;'">Insurance Partner</span>
            </v-btn>
          </v-item>
        </v-item-group>

        <v-card flat color="grey lighten-3" class="ma-3 rounded-xl">
          <div v-show="show_client_desktop">
            <div class="pa-2" style="margin-right: 50px; margin-left: 50px;">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-img contain :aspect-ratio="16/9" src="../assets/client_vendor_dan_etc/client_logo_only.png"></v-img>
              </v-row>
            </div>
          </div>

          <div v-show="show_vendor_desktop">
            <div class="pa-2" style="margin-right: 50px; margin-left: 50px;">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-img contain :aspect-ratio="16/9" src="../assets/client_vendor_dan_etc/vendor_logo_only.png"></v-img>
              </v-row>
            </div>
          </div>

          <div v-show="show_insurance_partner_desktop">
            <v-window v-model="window_insurance_partner_desktop" show-arrows>
              <template v-slot:prev="{ on, attrs }">
                <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
                  <v-btn
                    fab
                    :small="mobile ? true : false"
                    depressed
                    color="#eb2028"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="white">mdi-arrow-left-thick</v-icon>
                  </v-btn>
                </v-sheet>
              </template>
              <template v-slot:next="{ on, attrs }">
                <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
                  <v-btn
                    fab
                    :small="mobile ? true : false"
                    depressed
                    color="#eb2028"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="white">mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </v-sheet>
              </template>
              <v-window-item>
                <div class="pa-2" style="margin-right: 50px; margin-left: 50px;">
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-img contain :aspect-ratio="16/9" src="../assets/client_vendor_dan_etc/insurence_partner_logo_only_01.png"></v-img>
                    <!-- <img src="../assets/client_vendor_dan_etc/client_logo_only.png" loading="lazy" style="width: 100%; height: 100%; aspect-ratio: 16 / 9; object-fit: contain;"/> -->
                  </v-row>
                </div>  
              </v-window-item>
              <v-window-item>
                <div class="pa-2" style="margin-right: 50px; margin-left: 50px;">
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-img contain :aspect-ratio="16/9" src="../assets/client_vendor_dan_etc/insurence_partner_logo_only_02.png"></v-img>
                    <!-- <img src="../assets/client_vendor_dan_etc/vendor_logo_only.png" loading="lazy" style="width: 100%; height: 100%; aspect-ratio: 16 / 9; object-fit: contain;"/> -->
                  </v-row>
                </div>
              </v-window-item>
              <v-window-item>
                <!-- <div style="font-family: Museo Sans; font-size: 16px;" class="text-center mt-3 mb-n3">Broker & Adjuster</div> -->
                <div class="pa-2" style="margin-right: 50px; margin-left: 50px;">
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-img contain :aspect-ratio="16/9" src="../assets/client_vendor_dan_etc/broker_and_adjuster_desktop.png"></v-img>
                    <!-- <img src="../assets/client_vendor_dan_etc/vendor_logo_only.png" loading="lazy" style="width: 100%; height: 100%; aspect-ratio: 16 / 9; object-fit: contain;"/> -->
                  </v-row>
                </div>
              </v-window-item>
            </v-window>
            <v-row no-gutters justify="center" class="pb-3">
              <v-item-group
                v-model="window_insurance_partner_desktop"
                class="text-center"
                mandatory
              >
                <v-item
                  v-for="n in length_insurance_partner_desktop"
                  :key="`btn-${n}`"
                  v-slot="{ active, toggle }"
                >
                  <v-icon @click="toggle" :input-value="active" :color="active ? '#eb2028' : 'grey lighten-1'">mdi-record</v-icon>
                </v-item>
              </v-item-group>
            </v-row>
          </div>

          <!-- <div class="pb-3">
            <v-row no-gutters justify="center">
              <span style="font-family: Museo Sans;">Sesuai Urutan Abjad</span>
            </v-row>
          </div> -->
        </v-card>
      </v-col>

      <!-- <v-col cols="12" sm="10" md="8" lg="8">
        <v-card flat color="grey lighten-3" class="ma-5 rounded-xl">
          <v-item-group
            v-model="client"
            class="text-center"
            mandatory
            :height="mobile ? 'auto' : 'auto'"
            v-show="mobile == false"
          >
            <v-item
              v-for="(client, i) in btn_client_vendor_etc"
              :key="i"
              v-slot="{ active, toggle }"
            >
              <v-btn
                depressed
                class="text-capitalize ma-2 mt-5"
                style="font-family: Museo Sans; border-radius: 17px;"
                width="30%"
                :color="active ? '#eb2028' : 'grey lighten-2'"
                :input-value="active"
                @click="toggle"
              >
                <span style="font-family: Museo Sans;" :class="active ? 'white--text' : ''">{{ client.text }}</span>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-window show-arrows-on-hover v-model="client" show-arrows>
            <template v-slot:prev="{ on, attrs }">
              <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
                <v-btn
                  fab
                  :small="mobile ? true : false"
                  depressed
                  color="#eb2028"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="white">mdi-arrow-left-thick</v-icon>
                </v-btn>
              </v-sheet>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
                <v-btn
                  fab
                  :small="mobile ? true : false"
                  depressed
                  color="#eb2028"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="white">mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-sheet>
            </template>

            <v-window-item
              v-for="(client, i) in clients"
              :key="i"
            >
              <div :class="mobile ? 'pa-3' : ''">
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <img :src="client.src" loading="lazy" style="width: 100%; height: 100%; aspect-ratio: 20 / 10; object-fit: contain;"/>
                </v-row>
              </div>
            </v-window-item>
          </v-window>

          <v-card-actions v-show="mobile == true">
            <v-item-group
              v-model="client"
              class="mx-auto"
              mandatory
            >
              <v-item
                v-for="n in length"
                :key="`btn-${n}`"
                v-slot="{ active, toggle }"
              >
                <v-icon @click="toggle" :input-value="active" :color="active ? '#eb2028' : 'grey lighten-1'">mdi-record</v-icon>
              </v-item>
            </v-item-group>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>

    <!-- mobile -->
    <div v-show="mobile == true" class="text-center">
      <v-item-group class="mb-n3 mt-3 text-center" mandatory>
        <v-item>
          <v-btn small v-model="btn_client_mobile" @click="btn_client_mobile" class="text-capitalize ma-1" width="29%" depressed rounded height="45px" :color="show_client_mobile ? '#eb2028' : 'grey lighten-2'">
            <span :style="show_client_mobile ? 'color: white; font-family: Museo Sans; font-size: 12px;' : 'font-family: Museo Sans; font-size: 12px;'">Client</span>
          </v-btn>
        </v-item>
        <v-item>
          <v-btn small v-model="btn_vendor_mobile" @click="btn_vendor_mobile" class="text-capitalize ma-1" width="29%" depressed rounded height="45px" :color="show_vendor_mobile ? '#eb2028' : 'grey lighten-2'">
            <span :style="show_vendor_mobile ? 'color: white; font-family: Museo Sans; font-size: 12px;' : 'font-family: Museo Sans; font-size: 12px;'">Vendor</span>
          </v-btn>
        </v-item>
        <v-item>
          <v-btn small v-model="btn_insurance_partner_desktop" @click="btn_insurance_partner_mobile" class="text-capitalize ma-1" width="29%" rounded depressed height="45px" :color="show_insurance_partner_mobile ? '#eb2028' : 'grey lighten-2'">
            <span :style="show_insurance_partner_mobile ? 'color: white; font-family: Museo Sans; font-size: 12px;' : 'font-family: Museo Sans; font-size: 12px;'"><div>Insurance</div><div>Partner</div></span>
          </v-btn>
        </v-item>
      </v-item-group>

      <div>
        <v-card flat color="grey lighten-3" class="ma-5 rounded-xl">
          <div v-show="show_client_mobile" class="pa-2">
            <v-img contain :aspect-ratio="9/16" src="../assets/client_vendor_dan_etc/client_logo_only_mobile.png"></v-img>
          </div>

          <div v-show="show_vendor_mobile" class="pa-2">
            <v-img contain :aspect-ratio="9/16" src="../assets/client_vendor_dan_etc/vendor_logo_only_mobile.png"></v-img>
          </div>

          <div v-show="show_insurance_partner_mobile" class="pa-2">
            <v-window v-model="window_insurance_partner_mobile" show-arrows-on-hover show-arrows>
              <template v-slot:prev="{ on, attrs }">
                <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
                  <v-btn
                    fab
                    :small="mobile ? true : false"
                    depressed
                    color="#eb2028"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="white">mdi-arrow-left-thick</v-icon>
                  </v-btn>
                </v-sheet>
              </template>
              <template v-slot:next="{ on, attrs }">
                <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
                  <v-btn
                    fab
                    :small="mobile ? true : false"
                    depressed
                    color="#eb2028"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="white">mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </v-sheet>
              </template>
              <v-window-item>
                <v-img contain :aspect-ratio="9/16" src="../assets/client_vendor_dan_etc/insurance_partner_logo_only_mobile_01.png"></v-img>
                <!-- <img src="../assets/client_vendor_dan_etc/client_logo_only_mobile.png" loading="lazy" style="width: 100%; height: 100%; aspect-ratio: 16 / 9; object-fit: contain;"/> -->
              </v-window-item>
              <v-window-item>
                <v-img contain :aspect-ratio="9/16" src="../assets/client_vendor_dan_etc/insurance_partner_logo_only_mobile_02.png"></v-img>
                <!-- <img src="../assets/client_vendor_dan_etc/vendor_logo_only_mobile.png" loading="lazy" style="width: 100%; height: 100%; aspect-ratio: 16 / 9; object-fit: contain;"/> -->
              </v-window-item>
              <v-window-item>
                <v-img contain :aspect-ratio="9/16" src="../assets/client_vendor_dan_etc/insurance_partner_logo_only_mobile_03.png"></v-img>
                <!-- <img src="../assets/client_vendor_dan_etc/insurance_partner_logo_only_mobile.png" loading="lazy" style="width: 100%; height: 100%; aspect-ratio: 16 / 9; object-fit: contain;"/> -->
              </v-window-item>
              <v-window-item>
                <!-- <div style="font-family: Museo Sans;" class="text-center mt-2 mb-n2">Broker & Adjuster</div> -->
                <v-img contain :aspect-ratio="9/16" src="../assets/client_vendor_dan_etc/broker_and_adjuster_mobile.png"></v-img>
                <!-- <img src="../assets/client_vendor_dan_etc/insurance_partner_logo_only_mobile.png" loading="lazy" style="width: 100%; height: 100%; aspect-ratio: 16 / 9; object-fit: contain;"/> -->
              </v-window-item>
            </v-window>
            <v-row no-gutters justify="center">
              <v-item-group
                v-model="window_insurance_partner_mobile"
                class="text-center"
                mandatory
              >
                <v-item
                  v-for="n in length_insurance_partner_mobile"
                  :key="`btn-${n}`"
                  v-slot="{ active, toggle }"
                >
                  <v-icon @click="toggle" :input-value="active" :color="active ? '#eb2028' : 'grey lighten-1'">mdi-record</v-icon>
                </v-item>
              </v-item-group>
            </v-row>
          </div>

          <!-- <div class="pb-3">
            <v-row no-gutters justify="center">
              <span style="font-family: Museo Sans;">Sesuai Urutan Abjad</span>
            </v-row>
          </div> -->
        </v-card>
      </div>
    </div>

    


    <v-row v-show="mobile == true" no-gutters justify="center">
      <!-- <v-col>
        <v-card flat color="grey lighten-3" class="ma-5 rounded-xl">
          <v-responsive :aspect-ratio="9/16">
            <v-card-text>
              <v-window show-arrows-on-hover v-model="client" show-arrows>
                <v-window-item>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col cols="12">
                      <v-img :aspect-ratio="9/16" width="100%" height="auto" src="../assets/client_vendor_dan_etc/client_logo_only_mobile.png"></v-img>
                    </v-col>
                  </v-row>
                </v-window-item>

                <v-window-item>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col cols="12">
                      <v-img :aspect-ratio="9/16" width="100%" height="auto" src="../assets/client_vendor_dan_etc/vendor_logo_only_mobile.png"></v-img>
                    </v-col>
                  </v-row>
                </v-window-item>

                <v-window-item>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col cols="12">
                      <v-img :aspect-ratio="9/16" width="100%" height="auto" src="../assets/client_vendor_dan_etc/insurence_partner_logo_only_mobile.png"></v-img>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-responsive>
        </v-card>
      </v-col> -->
      <div>

      
      
      
      <!-- <div class="row mr-n6 ml-n6">
        <v-btn small v-model="btn_client_mobile" @click="btn_client_mobile" class="text-capitalize" width="33.3%" depressed rounded height="30px" :color="show_client_mobile ? '#eb2028' : 'grey lighten-2'">
          <span :style="show_client_mobile ? 'color: white; font-family: Museo Sans; font-size: 11px;' : 'font-family: Museo Sans; font-size: 11px;'">Client</span>
        </v-btn>
        <v-btn small v-model="btn_vendor_mobile" @click="btn_vendor_mobile" class="text-capitalize" width="33.3%" depressed rounded height="30px" :color="show_vendor_mobile ? '#eb2028' : 'grey lighten-2'">
          <span :style="show_vendor_mobile ? 'color: white; font-family: Museo Sans; font-size: 11px;' : 'font-family: Museo Sans; font-size: 11px;'">Vendor</span>
        </v-btn>
        <v-btn small v-model="btn_insurance_partner_desktop" @click="btn_insurance_partner_mobile" class="text-capitalize" width="33.3%" rounded depressed height="30px" :color="show_insurance_partner_mobile ? '#eb2028' : 'grey lighten-2'">
          <span :style="show_insurance_partner_mobile ? 'color: white; font-family: Museo Sans; font-size: 11px;' : 'font-family: Museo Sans; font-size: 11px;'">Insurance Partner</span>
        </v-btn>
      </div> -->


      <!-- <v-item-group class="mb-n3 mt-3 text-center" mandatory>
        <v-item>
          <v-btn small v-model="btn_client_mobile" @click="btn_client_mobile" class="text-capitalize" width="33%" depressed rounded height="30px" :color="show_client_mobile ? '#eb2028' : 'grey lighten-2'">
            <span :style="show_client_mobile ? 'color: white; font-family: Museo Sans; font-size: 11px;' : 'font-family: Museo Sans; font-size: 11px;'">Client</span>
          </v-btn>
        </v-item>
        <v-item>
          <v-btn small v-model="btn_vendor_mobile" @click="btn_vendor_mobile" class="text-capitalize" width="33%" depressed rounded height="30px" :color="show_vendor_mobile ? '#eb2028' : 'grey lighten-2'">
            <span :style="show_vendor_mobile ? 'color: white; font-family: Museo Sans; font-size: 11px;' : 'font-family: Museo Sans; font-size: 11px;'">Vendor</span>
          </v-btn>
        </v-item>
        <v-item>
          <v-btn small v-model="btn_insurance_partner_desktop" @click="btn_insurance_partner_mobile" class="text-capitalize" width="33%" rounded depressed height="30px" :color="show_insurance_partner_mobile ? '#eb2028' : 'grey lighten-2'">
            <span :style="show_insurance_partner_mobile ? 'color: white; font-family: Museo Sans; font-size: 11px;' : 'font-family: Museo Sans; font-size: 11px;'">Insurance Partner</span>
          </v-btn>
        </v-item>
      </v-item-group> -->
      </div>  

      
        
      <!-- <v-col cols="12">
        <v-card flat color="grey lighten-3" class="ma-5 rounded-xl">
          <v-window v-model="length_insurance_partner_mobile" show-arrows>
            <template v-slot:prev="{ on, attrs }">
              <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
                <v-btn
                  fab
                  :small="mobile ? true : false"
                  depressed
                  color="#eb2028"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="white">mdi-arrow-left-thick</v-icon>
                </v-btn>
              </v-sheet>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-sheet :class="mobile ? 'rounded-pill white pa-1' : 'rounded-pill white pa-2'">
                <v-btn
                  fab
                  :small="mobile ? true : false"
                  depressed
                  color="#eb2028"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="white">mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-sheet>
            </template>
            <v-window-item>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <div style="font-family: Museo Sans Bold; color: #eb2028; font-size: 18px;" class="mt-4 mb-n1">Client</div>
                <v-col cols="12">
                  <v-img :aspect-ratio="9/16" width="100%" height="auto" src="../assets/client_vendor_dan_etc/client_logo_only_mobile.png"></v-img>
                </v-col>
              </v-row>
            </v-window-item>

            <v-window-item>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <div style="font-family: Museo Sans Bold; color: #eb2028; font-size: 18px;" class="mt-4 mb-n1">Vendor</div>
                <v-col cols="12">
                  <v-img :aspect-ratio="9/16" width="100%" height="auto" src="../assets/client_vendor_dan_etc/vendor_logo_only_mobile.png"></v-img>
                </v-col>
              </v-row>
            </v-window-item>

            <v-window-item>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <div style="font-family: Museo Sans Bold; color: #eb2028; font-size: 18px;" class="mt-4 mb-n1">Insurance Partner</div>
                <v-col cols="12">
                  <v-img :aspect-ratio="9/16" width="100%" height="auto" src="../assets/client_vendor_dan_etc/insurance_partner_logo_only_mobile.png"></v-img>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>

          <v-row no-gutters justify="center">
            <v-item-group
              v-model="window_insurance_partner_desktop"
              class="text-center"
              mandatory
            >
              <v-item
                v-for="n in length_insurance_partner_desktop"
                :key="`btn-${n}`"
                v-slot="{ active, toggle }"
              >
                <v-icon @click="toggle" :input-value="active" :color="active ? '#eb2028' : 'grey lighten-1'">mdi-record</v-icon>
              </v-item>
            </v-item-group>
          </v-row>

          <div class="pb-3">
            <v-row no-gutters justify="center">
              <span style="font-family: Museo Sans;">Sesuai Urutan Abjad</span>
            </v-row>
          </div>


          <v-card-actions class="mt-n4">
            <v-item-group
              v-model="client"
              class="mx-auto mb-2"
              mandatory
            >
              <v-item
                v-for="n in length"
                :key="`btn-${n}`"
                v-slot="{ active, toggle }"
              >
                <v-icon @click="toggle" :input-value="active" :color="active ? '#eb2028' : 'grey lighten-1'">mdi-record</v-icon>
              </v-item>
            </v-item-group>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template> 

<script>
  export default {
    name: 'ClientView',

    data () {
      return {
        show_client_desktop: true,
        show_vendor_desktop: false,
        show_insurance_partner_desktop: false,

        show_client_mobile: true,
        show_vendor_mobile: false,
        show_insurance_partner_mobile: false,

        window_insurance_partner_desktop: 0,
        window_insurance_partner_mobile: 0,
        length_insurance_partner_desktop: 3,
        length_insurance_partner_mobile: 4,
        mobile:null,
        client: 0,
        length: 3,
        windowSize: {x: 0, y: 0},
        btn_client_vendor_etc: [
          { text: 'Client' },
          { text: 'Vendor' },
          { text: 'Insurance Partner' },
        ],
        // clients: [
        //   { text: 'Client', src: require('../assets/client_vendor_dan_etc/client_logo_only.png') },
        //   { text: 'Vendor', src: require('../assets/client_vendor_dan_etc/vendor_logo_only.png') },
        //   { text: 'Insurance Partner', src: require('../assets/client_vendor_dan_etc/insurence_partner_logo_only.png') },
        // ],
        // clients: [
        //   { text: 'Client', src: require('../assets/client_vendor_dan_etc/client_logo_only.png'), lazy: require('../assets/client_vendor_dan_etc/lazy/lazy_client_logo_only.png')},
        //   { text: 'Vendor', src: require('../assets/client_vendor_dan_etc/vendor_logo_only.png'), lazy: require('../assets/client_vendor_dan_etc/lazy/lazy_vendor_logo_only.png')},
        //   { text: 'Insurance Partner', src: require('../assets/client_vendor_dan_etc/insurence_partner_logo_only.png'), lazy: require('../assets/client_vendor_dan_etc/lazy/lazy_insurence_partner_logo_only.png')},
        // ],

      }
    },

    watch: {
      windowSize(){
        if (this.windowSize.x < 450) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false  
        }
      },
    },

    methods: {
      btn_client_desktop(){
        this.show_client_desktop = true
        this.show_vendor_desktop = false
        this.show_insurance_partner_desktop = false
      },
      btn_vendor_desktop(){
        this.show_client_desktop = false
        this.show_vendor_desktop = true
        this.show_insurance_partner_desktop = false
      },
      btn_insurance_partner_desktop(){
        this.show_client_desktop = false
        this.show_vendor_desktop = false
        this.show_insurance_partner_desktop = true
      },

      btn_client_mobile(){
        this.show_client_mobile = true
        this.show_vendor_mobile = false
        this.show_insurance_partner_mobile = false
      },
      btn_vendor_mobile(){
        this.show_client_mobile = false
        this.show_vendor_mobile = true
        this.show_insurance_partner_mobile = false
      },
      btn_insurance_partner_mobile(){
        this.show_client_mobile = false
        this.show_vendor_mobile = false
        this.show_insurance_partner_mobile = true
      },
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
    },
  }
</script>