<template>
  <div v-resize="onResize">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <div>
          <div class="text-center">
            <h1 :style="mobile ? 'font-family: Museo Sans Bold; font-size: 30px;' : 'font-family: Museo Sans Bold; font-size: 40px;'">Workshop</h1>
            <div :style="mobile ? 'font-family: Museo Sans; font-weight: normal; font-size: 30px; margin-top: -5px;' : 'font-family: Museo Sans; font-weight: normal; font-size: 40px; margin-top: -5px;'">& Jangkauan Wilayah</div>
          </div>

          <div class="text-center" :class="mobile ? 'mt-3' : 'mt-5'">

            <v-card flat :width="mobile ? '100%' : '80%'" class="mx-auto transparent">
              <v-menu open-on-hover top rounded="xl" v-model="show_point_palembang" :close-on-content-click="false" :nudge-width="200" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_palembang" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <v-card v-show="mobile == false" color="grey darken-1" width="520px">
                  <div class="row no-gutters pa-2">
                    <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/palembang.jpg"></v-img>
                    <v-sheet color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                      <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Palembang</div>
                      <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer;"><a target="_blank" href="https://www.google.com/maps/place/PT.TRINITY+AUTO/@-2.9672456,104.7118211,15z/data=!4m6!3m5!1s0x2e3b7553587c29cb:0xaf29c1e84517df19!8m2!3d-2.9672456!4d104.7118211!16s%2Fg%2F11hyt5r821?entry=ttu">Jl. Soekarno Hatta No.83, Siring Agung, Kec. Ilir Bar. I, Kota Palembang, Sumatera Selatan 30153</a></div>
                      <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px;"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 813 5130 8886</div>
                      <div><v-btn href="tel:+62 813 5130 8886" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                    </v-sheet>
                  </div>
                </v-card>
                <v-card v-show="mobile == true" color="grey darken-1">
                  <div class="pa-2">
                    <v-card flat class="rounded-lg white mx-auto">
                      <v-list-item three-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_palembang = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/palembang.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Palembang</div>
                          <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/PT.TRINITY+AUTO/@-2.9672456,104.7118211,15z/data=!4m6!3m5!1s0x2e3b7553587c29cb:0xaf29c1e84517df19!8m2!3d-2.9672456!4d104.7118211!16s%2Fg%2F11hyt5r821?entry=ttu">Jl. Soekarno Hatta No.83, Siring Agung, Kec. Ilir Bar. I, Kota Palembang, Sumatera Selatan 30153</a></div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px;"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 813 5130 8886</div>
                          <div><v-btn href="tel:+62 813 5130 8886" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-card>
              </v-menu>

              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_palembang" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2 row">
                  <v-sheet height="200px" width="300px" color="white">
                    <v-img height="200px" width="300px" src="../assets/foto_cabang/palembang.png"></v-img>
                  </v-sheet>
                  <v-sheet height="200px" width="300px">
                    <img height="200px" width="300px" style="object-fit: cover;" loading="lazy" src="../assets/foto_cabang/palembang.png"/>
                    <div style="margin-top: -34px;; position: absolute; padding: 3px; background-color: rgba(255, 255, 255, 0.9) ; width: 300px;" class="text-center">
                      <span style="font-family: Museo Sans Bold; font-size: 14px;">Trinity Auto - Palembang</span>
                    </div>
                  </v-sheet>
                  <v-sheet height="200px" width="200px" color="white" class="pa-2">
                    <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Palembang</div>
                    <div style="font-family: Museo Sans; font-size: 12px;">Jl. Soekarno Hatta No.83, Siring Agung, Kec. Ilir Bar. I, Kota Palembang, Sumatera Selatan 30153</div>
                    <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px">+62 711 5562 844</div>
                    <div><v-btn x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <v-menu v-model="show_point_sungai_danau" open-on-hover top rounded="xl" :close-on-content-click="false" :nudge-width="300" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_sungai_danau" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <v-card v-show="mobile == false" color="grey darken-1" width="520px">
                  <div class="row no-gutters pa-2">
                    <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/sungai_danau.jpg"></v-img>
                    <v-sheet color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                      <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Sungai Danau</div>
                      <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto+5/@-2.1348342,115.0055721,8z/data=!4m6!3m5!1s0x2de63bca1d7d7f85:0x85fc520668a285a0!8m2!3d-3.7596193!4d115.4537901!16s%2Fg%2F11f78mb3vf?entry=ttu">Jl. Ahmad Yani, Poros Batu licin-Pelaihari, Satui Barat, Sungai Danau, Kab. Tanah Bumbu, Kalimantan Selatan 72276</a></div>
                      <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 852 5042 6978</div>
                      <div><v-btn href="tel:+62 852 5042 6978" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                    </v-sheet>
                  </div>
                </v-card>
                <v-card v-show="mobile == true" color="grey darken-1">
                  <div class="pa-2">
                    <v-card flat class="rounded-lg white mx-auto">
                      <v-list-item three-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_sungai_danau = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/sungai_danau.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Sungai Danau</div>
                          <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto+5/@-2.1348342,115.0055721,8z/data=!4m6!3m5!1s0x2de63bca1d7d7f85:0x85fc520668a285a0!8m2!3d-3.7596193!4d115.4537901!16s%2Fg%2F11f78mb3vf?entry=ttu">Jl. Ahmad Yani, Poros Batu licin-Pelaihari, Satui Barat, Sungai Danau, Kab. Tanah Bumbu, Kalimantan Selatan 72276</a></div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 852 5042 6978</div>
                          <div><v-btn href="tel:+62 852 5042 6978" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-card>
              </v-menu>

              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_sungai_danau" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2 row">
                  <v-sheet height="200px" width="300px" color="white">
                    <v-img height="200px" width="300px" src="../assets/foto_cabang/sungai_danau.png"></v-img>
                  </v-sheet>

                  <v-sheet height="200px" width="300px" color="white">
                    <img height="200px" width="300px" style="object-fit: cover;" loading="lazy" src="../assets/foto_cabang/sungai_danau.png"/>
                    <div style="margin-top: -34px;; position: absolute; padding: 3px; background-color: rgba(255, 255, 255, 0.9) ; width: 300px;" class="text-center">
                      <span style="font-family: Museo Sans Bold; font-size: 14px;">Trinity Auto - Sungai Danau</span>
                    </div>
                  </v-sheet>

                  <v-sheet height="200px" width="200px" color="white" class="pa-2">
                    <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Sungai Danau</div>
                    <div style="font-family: Museo Sans; font-size: 12px;">Jl. Ahmad Yani, Poros Batu licin-Pelaihari, Satui Barat, Sungai Danau, Kab. Tanah Bumbu, Kalimantan Selatan 72276</div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <v-menu v-model="show_point_tanjung" open-on-hover top rounded="xl" :close-on-content-click="false" :nudge-width="300" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_tanjung" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <v-card v-show="mobile == false" color="grey darken-1" width="520px">
                  <div class="row no-gutters pa-2">
                    <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/tanjung.jpg"></v-img>
                    <v-sheet color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                      <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Tanjung</div>
                      <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer;"><a target="_blank" href="https://www.google.com/maps/place/TRINITY+AUTO+tanjung/@-2.1100864,115.4692037,17z/data=!3m1!4b1!4m6!3m5!1s0x2dfab1d1a9230cbb:0xfabbbe29d5653318!8m2!3d-2.1100864!4d115.4692037!16s%2Fg%2F11stzz17pv?entry=ttu">VFQ9+XM, Kasiau, Murung Pudak, Tabalong Regency, South Kalimantan</a></div>
                      <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 813 5169 2812</div>
                      <div><v-btn href="tel:+62 813 5169 2812" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                    </v-sheet>
                  </div>
                </v-card>
                <v-card v-show="mobile == true" color="grey darken-1">
                  <div class="pa-2">
                    <v-card flat class="rounded-lg white mx-auto">
                      <v-list-item three-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_tanjung = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/tanjung.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Tanjung</div>
                          <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/TRINITY+AUTO+tanjung/@-2.1100864,115.4692037,17z/data=!3m1!4b1!4m6!3m5!1s0x2dfab1d1a9230cbb:0xfabbbe29d5653318!8m2!3d-2.1100864!4d115.4692037!16s%2Fg%2F11stzz17pv?entry=ttu">VFQ9+XM, Kasiau, Murung Pudak, Tabalong Regency, South Kalimantan</a></div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 813 5169 2812</div>
                          <div><v-btn href="tel:+62 813 5169 2812" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-card>
              </v-menu>

              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_tanjung" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2 row">
                  <v-sheet height="200px" width="300px" color="white">
                    <v-img height="200px" width="300px" src="../assets/foto_cabang/logo_trinity.png"></v-img>
                  </v-sheet>
                  <v-sheet height="200px" width="200px" color="white" class="pa-2">
                    <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Tanjung</div>
                    <div style="font-family: Museo Sans; font-size: 12px;">NAN</div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <v-menu v-model="show_point_tabang" open-on-hover top rounded="xl" :close-on-content-click="false" :nudge-width="300" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_tabang" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <v-card v-show="mobile == false" color="grey darken-1" width="520px">
                  <div class="row no-gutters pa-2">
                    <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/tabang.jpg"></v-img>
                    <v-sheet color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                      <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Tabang</div>
                      <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Pt+Trinity+Auto/@0.5352944,116.1010182,15z/data=!3m1!4b1!4m6!3m5!1s0x32085f9b9f186aff:0x2cbdc95494f54b5a!8m2!3d0.535273!4d116.1194724!16s%2Fg%2F11s69bdhxv?entry=tts&g_ep=EgoyMDI0MDUyMi4wKgBIAVAD">Lokasi PT. Fajar Sakti Prima Desa. Umaq Dian, Kec. Tabang, Kab. Kutai Kartanegara, Kalimantan Timur 75558</a></div>
                      <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 821 3900 0302</div>
                      <div><v-btn href="tel:+62 821 3900 0302" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                    </v-sheet>
                  </div>
                </v-card>
                <v-card v-show="mobile == true" color="grey darken-1">
                  <div class="pa-2">
                    <v-card flat class="rounded-lg white mx-auto">
                      <v-list-item three-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_tabang = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/tabang.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Tabang</div>
                          <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Pt+Trinity+Auto/@0.5352944,116.1010182,15z/data=!3m1!4b1!4m6!3m5!1s0x32085f9b9f186aff:0x2cbdc95494f54b5a!8m2!3d0.535273!4d116.1194724!16s%2Fg%2F11s69bdhxv?entry=tts&g_ep=EgoyMDI0MDUyMi4wKgBIAVAD">Lokasi PT. Fajar Sakti Prima Desa. Umaq Dian, Kec. Tabang, Kab. Kutai Kartanegara, Kalimantan Timur 75558</a></div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 821 3900 0302</div>
                          <div><v-btn href="tel:+62 821 3900 0302" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-card>
              </v-menu>

              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_tabang" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2 row">
                  <v-sheet height="200px" width="300px" color="white">
                    <v-img height="200px" width="300px" src="../assets/foto_cabang/tabang.png"></v-img>
                  </v-sheet>
                  <v-sheet height="200px" width="300px" color="white">
                    <img height="200px" width="300px" style="object-fit: cover;" loading="lazy" src="../assets/foto_cabang/tabang.png"/>
                    <div style="margin-top: -34px;; position: absolute; padding: 3px; background-color: rgba(255, 255, 255, 0.9) ; width: 300px;" class="text-center">
                      <span style="font-family: Museo Sans Bold; font-size: 14px;">Trinity Auto - Tabang</span>
                    </div>
                  </v-sheet>
                  <v-sheet height="200px" width="200px" color="white" class="pa-2">
                    <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Tabang</div>
                    <div style="font-family: Museo Sans; font-size: 12px;">Lokasi PT. Fajar Sakti Prima Desa. Umaq Dian, Kec. Tabang, Kab. Kutai Kartanegara, Kalimantan Timur 75558</div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <v-menu v-model="show_point_berau" open-on-hover top rounded="xl" :close-on-content-click="false" :nudge-width="300" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_berau" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <v-card v-show="mobile == false" color="grey darken-1" width="520px">
                  <div class="row no-gutters pa-2">
                    <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/berau.jpg"></v-img>
                    <v-sheet :aspect-ratio="1/1" color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                      <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Berau</div>
                      <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto+-+Berau/@2.146888,117.4694544,15z/data=!4m6!3m5!1s0x320df5b5cd72ee99:0xb843123af7fe26f0!8m2!3d2.146888!4d117.4694544!16s%2Fg%2F11hz8z9h98?entry=ttu">Jl. Sultan Agung RT. VI, Depan Taman TPA Jl. Bujangga, Rinding, Tanjung Redeb, Berau Regency, East Kalimantan 77352</a></div>
                      <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 812 5349 233 (CAR)</div>
                      <div style="font-family: Museo Sans; font-size: 12px; margin-top: -3px; margin-bottom: 2px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 813 4711 1523 (HE)</div>
                      <div class="row no-gutters">
                        <v-btn href="tel:+62 812 5349 233" x-small color="#eb2028" depressed rounded class="text-capitalize white--text mr-1">Contact Us (CAR)</v-btn>
                        <v-btn href="tel:+62 813 4711 1523" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us (HE)</v-btn>
                      </div>
                    </v-sheet>
                  </div>
                </v-card>
                <v-card v-show="mobile == true" color="grey darken-1">
                  <div class="pa-2">
                    <v-card flat class="rounded-lg white mx-auto">
                      <v-list-item three-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_berau = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/berau.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Berau</div>
                          <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto+-+Berau/@2.146888,117.4694544,15z/data=!4m6!3m5!1s0x320df5b5cd72ee99:0xb843123af7fe26f0!8m2!3d2.146888!4d117.4694544!16s%2Fg%2F11hz8z9h98?entry=ttu">Jl. Sultan Agung RT. VI, Depan Taman TPA Jl. Bujangga, Rinding, Tanjung Redeb, Berau Regency, East Kalimantan 77352</a></div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 812 5349 233 (CAR)</div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: -3px; margin-bottom: 2px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 813 4711 1523 (HE)</div>
                          <div class="row no-gutters">
                            <v-btn href="tel:+62 812 5349 233" x-small color="#eb2028" depressed rounded class="text-capitalize white--text mb-1">Contact Us (CAR)</v-btn>
                            <v-btn href="tel:+62 813 4711 1523" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us (HE)</v-btn>
                          </div>
                          <!-- <div><v-btn href="tel:+62 812 5349 233" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us (CAR)</v-btn></div>
                          <div><v-btn href="tel:+62 813 4711 1523" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us (HE)</v-btn></div> -->
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-card>
              </v-menu>

              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_berau" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2 row">
                  <v-sheet height="200px" width="300px" color="white">
                    <v-img height="200px" width="300px" src="../assets/foto_cabang/berau.png"></v-img>
                  </v-sheet>
                  <v-sheet height="200px" width="300px" color="white">
                    <img height="200px" width="300px" style="object-fit: cover;" loading="lazy" src="../assets/foto_cabang/berau.png"/>
                    <div style="margin-top: -34px;; position: absolute; padding: 3px; background-color: rgba(255, 255, 255, 0.8) ; width: 300px;" class="text-center">
                      <span style="font-family: Museo Sans Bold; font-size: 14px;">Trinity Auto - Berau</span>
                    </div>
                  </v-sheet>
                  <v-sheet height="200px" width="200px" color="white" class="pa-2">
                    <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Berau</div>
                    <div style="font-family: Museo Sans; font-size: 12px;">Jl. Sultan Agung RT. VI, Depan Taman TPA Jl. Bujangga, Rinding, Tanjung Redeb, Berau Regency, East Kalimantan 77352</div>
                    <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px">+62 822 3058 7777</div>
                    <div><v-btn x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <v-menu v-model="show_point_morowali" open-on-hover top rounded="xl" :close-on-content-click="false" :nudge-width="300" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_morowali" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <v-card v-show="mobile == false" color="grey darken-1" width="520px">
                  <div class="row no-gutters pa-2">
                    <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/morowali.jpg"></v-img>
                    <v-sheet :aspect-ratio="1/1" color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                      <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Morowali</div>
                      <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto+-+Morowali/@-2.8237993,122.183604,15z/data=!4m6!3m5!1s0x2d9a4f4b9154de6b:0x8b29a64c205d6837!8m2!3d-2.8237993!4d122.183604!16s%2Fg%2F11rmv_cgbw?entry=ttu">Jl. Trans Sulawesi, Labota, Kec. Bahodopi, Kabupaten Morowali, Sulawesi Tengah</a></div>
                      <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 852 4600 0511</div>
                      <div><v-btn href="tel:+62 852 4600 0511" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                    </v-sheet>
                  </div>
                </v-card>
                <v-card v-show="mobile == true" color="grey darken-1">
                  <div class="pa-2">
                    <v-card flat class="rounded-lg white mx-auto">
                      <v-list-item three-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_morowali = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/morowali.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Morowali</div>
                          <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto+-+Morowali/@-2.8237993,122.183604,15z/data=!4m6!3m5!1s0x2d9a4f4b9154de6b:0x8b29a64c205d6837!8m2!3d-2.8237993!4d122.183604!16s%2Fg%2F11rmv_cgbw?entry=ttu">Jl. Trans Sulawesi, Labota, Kec. Bahodopi, Kabupaten Morowali, Sulawesi Tengah</a></div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 852 4600 0511</div>
                      <div><v-btn href="tel:+62 852 4600 0511" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-card>
              </v-menu>

              <!-- <v-tooltip v-model="show_morowali" top :open-on-hover="true" :open-on-click="true" @click:outside="onClickOutside = false">
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_morowali" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2 row">
                  <v-sheet height="200px" width="300px" color="white">
                    <v-img height="200px" width="300px" src="../assets/foto_cabang/morowali.png"></v-img>
                  </v-sheet>
                  <v-sheet height="200px" width="200px" color="white" class="pa-2">
                    <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Morowali</div>
                    <div style="font-family: Museo Sans; font-size: 12px;">Jl. Trans Sulawesi, Labota, Kec. Bahodopi, Kabupaten Morowali, Sulawesi Tengah</div>
                    <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px">+62 822 3143 7777</div>
                    <div><v-btn x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                  </v-sheet>
                </div>
                <div class="ma-1 ml-n2 mr-n2">
                  <v-sheet height="200px" width="300px" color="white">
                    <img height="200px" width="300px" style="object-fit: cover;" loading="lazy" src="../assets/foto_cabang/morowali.png"/>
                    <div style="margin-top: -34px;; position: absolute; padding: 3px; background-color: rgba(255, 255, 255, 0.8) ; width: 300px;" class="text-center">
                      <span style="font-family: Museo Sans Bold; font-size: 14px;">Trinity Auto - Morowali</span>
                    </div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <v-menu v-model="show_point_halmahera" open-on-hover top rounded="xl" :close-on-content-click="false" :nudge-width="300" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_halmahera" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <v-card v-show="mobile == false" color="grey darken-1" width="520px">
                  <div class="row no-gutters pa-2">
                    <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/halmahera.jpg"></v-img>
                    <v-sheet :aspect-ratio="1/1" color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                      <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Halmahera</div>
                      <div style="font-family: Museo Sans; font-size: 12px;">Coming Soon</div>
                    </v-sheet>
                  </div>
                </v-card>
                <v-card v-show="mobile == true" color="grey darken-1">
                  <div class="pa-2">
                    <v-card flat class="rounded-lg white mx-auto">
                      <v-list-item two-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_halmahera = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/halmahera.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Halmahera</div>
                          <div style="font-family: Museo Sans; font-size: 12px; line-height: 1rem;">Coming Soon</div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-card>
              </v-menu>

              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_halmahera" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2">
                  <v-sheet height="200px" width="300px" color="white">
                    <img height="200px" width="300px" style="object-fit: cover;" loading="lazy" src="../assets/foto_cabang/logo_trinity.png"/>
                    <div style="margin-top: -34px; position: absolute; padding: 3px; background-color: rgba(255, 255, 255, 0.8) ; width: 300px;" class="text-center">
                      <span style="font-family: Museo Sans Bold; font-size: 14px;">Coming Soon</span>
                    </div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_map_halmahera" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2 row">
                  <v-sheet height="200px" width="300px">
                    <v-img height="200px" width="300px" src="../assets/foto_cabang/logo_trinity.png"></v-img>
                  </v-sheet>
                  <v-sheet height="200px" width="300px" color="white">
                    <img height="200px" width="300px" style="object-fit: cover;" loading="lazy" src="../assets/foto_cabang/logo_trinity.png"/>
                    <div style="margin-top: -34px; position: absolute; padding: 3px; background-color: rgba(255, 255, 255, 0.8) ; width: 300px;" class="text-center">
                      <span style="font-family: Museo Sans Bold; font-size: 14px;">Coming Soon</span>
                    </div>
                  </v-sheet>
                  <v-sheet height="200px" width="200px" color="white" class="pa-2">
                    <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Halmahera</div>
                    <div style="font-family: Museo Sans; font-size: 12px;">Coming Soon</div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <v-menu v-model="show_point_head_office" open-on-hover top rounded="xl" :close-on-content-click="false" :nudge-width="300" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-img style="cursor: pointer;" class="point_center_head_office" v-bind="attrs" v-on="on" src="../assets/maps_point_center.png"></v-img>
                </template>
                <v-card v-show="mobile == false" color="grey darken-1" width="520px">
                  <div class="row no-gutters pa-2">
                    <div class="row no-gutters mb-2">
                      <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/head_office.jpg"></v-img>
                      <v-sheet :aspect-ratio="1/1" color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                        <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Samarinda</div>
                        <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto/@-0.5145251,117.130261,15z/data=!4m6!3m5!1s0x2df67fb049ebe3df:0x63e44e9e5816eb78!8m2!3d-0.5145251!4d117.130261!16s%2Fg%2F1hd_5lxq2?entry=ttu">Jl. Bung Tomo No.49, Sungai keledang, kec. Samarinda Seberang, Kota Samarinda, Kalimantan Timur, Indonesia</a></div>
                        <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 541 262 800</div>
                        <div><v-btn href="tel:+62 541 262 800" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                      </v-sheet>
                    </div>
                    <div class="row no-gutters">
                      <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="auto" width="50%" src="../assets/foto_cabang/simpang_pasir.jpg"></v-img>
                      <v-sheet :aspect-ratio="1/1" color="white" class="rounded-r-lg pa-2" height="auto" width="50%">
                      <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Heavy Equipment</div>
                      <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto+-+Heavy+Equipment/@-0.5719892,117.1415097,16z/data=!4m6!3m5!1s0x2df681b3492c16f5:0x58e04a77af68d140!8m2!3d-0.569291!4d117.1430482!16s%2Fg%2F11cs23z0fd?entry=ttu">Simpang Pasir, Kec. Palaran, Kota Samarinda, Kalimantan Timur 75251</a></div>
                      <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 541 262 800</div>
                      <div><v-btn href="tel:+62 541 262 800" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                    </v-sheet>
                    </div>
                  </div>
                </v-card>

                <v-card v-show="mobile == true" color="grey darken-1">
                  <div class="pa-2">
                    <v-card flat class="rounded-lg white mx-auto">
                      <v-list-item three-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_head_office_bung_tomo = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/head_office.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Samarinda</div>
                          <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto/@-0.5145251,117.130261,15z/data=!4m6!3m5!1s0x2df67fb049ebe3df:0x63e44e9e5816eb78!8m2!3d-0.5145251!4d117.130261!16s%2Fg%2F1hd_5lxq2?entry=ttu">Jl. Bung Tomo No.49, Sungai keledang, kec. Samarinda Seberang, Kota Samarinda, Kalimantan Timur, Indonesia</a></div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 541 262 800</div>
                          <div><v-btn href="tel:+62 541 262 800" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                    <v-card flat class="rounded-lg white mx-auto mt-2">
                      <v-list-item three-line>
                        <v-list-item-avatar
                          class="rounded-lg"
                          size="50"
                          color="grey"
                          @click="dialog_foto_head_office_simpang_pasir = true"
                        >
                          <v-img :aspect-ratio="1/1" src="../assets/foto_cabang/simpang_pasir.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Heavy Equipment</div>
                          <div style="font-family: Museo Sans; font-size: 12px; cursor: pointer; line-height: 1rem;"><a target="_blank" href="https://www.google.com/maps/place/Trinity+Auto+-+Heavy+Equipment/@-0.5719892,117.1415097,16z/data=!4m6!3m5!1s0x2df681b3492c16f5:0x58e04a77af68d140!8m2!3d-0.569291!4d117.1430482!16s%2Fg%2F11cs23z0fd?entry=ttu">Simpang Pasir, Kec. Palaran, Kota Samarinda, Kalimantan Timur 75251</a></div>
                          <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px"><v-icon size="18px" color="black" style="margin-bottom: 2px;">mdi-phone</v-icon>+62 541 262 800</div>
                          <div><v-btn href="tel:+62 541 262 800" x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-card>
              </v-menu>

              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs, }">
                  <v-img style="cursor: pointer;" class="point_center_head_office" v-bind="attrs" v-on="on" src="../assets/maps_point_center.png"></v-img>
                </template>
                <div class="ma-1 ml-n2 mr-n2 row">
                  <v-sheet height="200px" width="300px" color="white">
                    <img height="200px" width="300px" style="object-fit: cover;" loading="lazy" src="../assets/foto_cabang/head_office.png"/>
                    <div style="margin-top: -34px;; position: absolute; padding: 3px; background-color: rgba(255, 255, 255, 0.8) ; width: 300px;" class="text-center">
                      <span style="font-family: Museo Sans Bold; font-size: 14px;">Trinity Auto - Samarinda</span>
                    </div>
                  </v-sheet>
                  <v-sheet height="200px" width="300px" color="white">
                    <v-img height="200px" width="300px" src="../assets/foto_cabang/head_office.png"></v-img>
                  </v-sheet>
                  <v-sheet height="200px" width="200px" color="white" class="pa-2">
                    <div style="font-family: Museo Sans Bold; font-size: 15px; margin-bottom: 5px">Trinity Auto - Samarinda</div>
                    <div style="font-family: Museo Sans; font-size: 12px;">Jl. Bung Tomo No.49, Sungai keledang, kec. Samarinda Seberang, Kota Samarinda, Kalimantan Timur, Indonesia</div>
                    <div style="font-family: Museo Sans; font-size: 12px; margin-top: 5px">+62 541 262 800</div>
                    <div><v-btn x-small color="#eb2028" depressed rounded class="text-capitalize white--text">Contact Us</v-btn></div>
                  </v-sheet>
                </div>
              </v-tooltip> -->

              <img loading="lazy" style="z-index: -1; object-fit: contain; aspect-ratio: 16 / 6.3;" width="100%" src="../assets/jangkauan/maps.png"/>
            </v-card>

            <!-- <v-img contain :width="mobile ? '100%' : '80%'" :aspect-ratio="16 / 6.3" height="auto" class="mx-auto" lazy-src="../assets/jangkauan/lazy_maps.png" src="../assets/jangkauan/maps.png">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    rounded
                    :size="mobile ? '' : '50'"
                    :width="mobile ? '' : '5'"
                    color="grey"
                  ></v-progress-circular>
                </v-row>
              </template>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img style="cursor: pointer;" class="point_map_palembang" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                  </template>
                  <span>Trinity Auto - Palembang</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img style="cursor: pointer;" class="point2" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                  </template>
                  <span>Trinity Auto - Sungai Danau</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img style="cursor: pointer;" class="point_map_tabang" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                  </template>
                  <span>Trinity Auto - Tabang</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img style="cursor: pointer;" class="point_map_berau" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                  </template>
                  <span>Trinity Auto - Berau</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img style="cursor: pointer;" class="point_map_morowali" v-bind="attrs" v-on="on" src="../assets/maps_point.png"></v-img>
                  </template>
                  <span>Trinity Auto - Morowali</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs, }">
                    <v-img style="cursor: pointer;" class="point_center_head_office" v-bind="attrs" v-on="on" src="../assets/maps_point_center.png"></v-img>
                  </template>
                  <span>Trinity Auto</span>
                </v-tooltip>
            </v-img> -->
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_foto_palembang" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/palembang.jpg"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_foto_sungai_danau" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/sungai_danau.jpg"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_foto_tanjung" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/tanjung.jpg"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_foto_tabang" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/tabang.jpg"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_foto_berau" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/berau.jpg"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_foto_morowali" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/morowali.jpg"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_foto_halmahera" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/halmahera.jpg"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_foto_head_office_bung_tomo" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/head_office.jpg"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_foto_head_office_simpang_pasir" max-width="300">
      <v-card class="rounded-lg">
        <v-img :aspect-ratio="4.4/3" class="rounded-l-lg" height="200px" width="300px" src="../assets/foto_cabang/simpang_pasir.jpg"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template> 

<script>
  export default {
    name: 'WorkshopView',

    data () {
      return {
        dialog_foto_palembang: false,
        dialog_foto_sungai_danau: false,
        dialog_foto_tanjung: false,
        dialog_foto_tabang: false,
        dialog_foto_berau: false,
        dialog_foto_morowali: false,
        dialog_foto_halmahera: false,
        dialog_foto_head_office_bung_tomo: false,
        dialog_foto_head_office_simpang_pasir: false,
        show_point_palembang: false,
        show_point_sungai_danau: false,
        show_point_tanjung: false,
        show_point_tabang: false,
        show_point_berau: false,
        show_point_morowali: false,
        show_point_halmahera: false,
        show_point_head_office: false,
        mobile:null,
        windowSize: {x: 0, y: 0},
      }
    },

    watch: {
      windowSize(){
        if (this.windowSize.x < 450) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false  
        }
      },
    },

    methods: {
      onClickOutside () {
        this.show_morowali = false
      },
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
    },
  }
</script>

<style scoped>
  .point_center_head_office {
    margin-top: 15.2%;
    margin-left: 46.2%;
    position: absolute;
    width: 3.2%;
    height: auto;
  }
  .point_map_palembang {
    margin-top: 21.3%;
    margin-left: 19.8%;
    position: absolute;
    width: 2%;
    height: auto;
  }
  .point_map_sungai_danau {
    margin-top: 22.8%;
    margin-left: 42.5%;
    position: absolute;
    width: 2%;
    height: auto;
  }
  .point_map_tabang {
    margin-top: 14.4%;
    margin-left: 43.7%;
    position: absolute;
    width: 2%;
    height: auto;
  }
  .point_map_berau {
    margin-top: 9.8%;
    margin-left: 48.5%;
    position: absolute;
    width: 2%;
    height: auto;
  }
  .point_map_morowali {
    margin-top: 20.3%;
    margin-left: 57.4%;
    position: absolute;
    width: 2%;
    height: auto;
  }
  .point_map_halmahera {
    margin-top: 12.8%;
    margin-left: 70.1%;
    position: absolute;
    width: 2%;
    height: auto;
  }
  .point_map_tanjung {
    margin-top: 18.7%;
    margin-left: 42.7%;
    position: absolute;
    width: 2%;
    height: auto;
  }
</style>